export const chipDropdownStyle = {
  display: 'flex',
  height: '32px',
  paddingTop: '6px',
  paddingRight: '8px',
  paddingBottom: '6px',
  paddingLeft: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '8px',
  width: '100px'
};
export const chipDropdownStyleBig = {
  display: 'flex',
  height: '32px',
  paddingTop: '6px',
  paddingRight: '8px',
  paddingBottom: '6px',
  paddingLeft: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '8px'
};
export const modalDialogHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
};
export const modalDialogSubHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingTop: '8px'
};
export const modalDialogTitle = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  fontFamily: 'Inter',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '32px'
};
export const modalDialogTitleContent = {
  color: 'var(--M3-sys-light-on-surface-variant, #3F4949)',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.25px'
}
export const modalDialogSubTitle = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px'
};
export const menuOptionText = {
  color: 'var(--M3-sys-light-on-surface-variant, #3F4949)',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '0.1px'
}
export const buttonBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  alignSelf: 'stretch',
  position: 'sticky',
  bottom: '0',
  backgroundColor: '#E6E9E8',
  zIndex: '2'
}
export const buttonBoxBottom = {
  display: 'flex',
  padding: '24px 0px 24px 8px',
  alignItems: 'center',
  gap: '8px'
}
export const cancelButtonStyle = {
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: 14,
  color: '#00696D',
  padding: '10px',
  background: 'transparent'
};
export const okButtonStyle = {
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: 14,
  color: '#ffffff',
  padding: '10px',
  backgroundColor: '#00696D'
};
export const assetAdd = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px'
};
export const assetAddHeader = {
  color: 'var(--M3-sys-light-on-surface-variant, #3F4949)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '28px'
};
export const cancelModalBox = {
  display: 'flex',
  padding: '24px 24px 0px 24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch'
}
export const cancelModalTitle = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  fontFamily: 'Inter',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '32px'
}
export const cancelModalSubTitle = {
  color: 'var(--M3-sys-light-on-surface-variant, #3F4949)',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.25px'
}
export const cancelModalBoxButton = {
  display: 'flex',
  padding: '24px 24px 24px 8px',
  alignItems: 'center',
  gap: '8px'
}
export const dualTextBox = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px'
};
export const headerSticky = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  alignSelf: 'stretch',
  position: 'sticky',
  top: '0px',
  backgroundColor: 'rgb(230, 233, 232)',
  zIndex: '2',
  paddingTop: '24px',
  paddingBottom: '20px'
}