export function template1(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName,
      locationId: formData.locationId,
      ensilationText: formData.ensilationText,
      ensilationAmount: formData.ensilationAmount,
      drymatter: formData.drymatter,
      weight: formData.weight
    },
    locationId: formData.locationId
  };
}
export function template2(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName,
      cutUnit: formData.cutUnit,
      ensilationText: formData.ensilationText,
      ensilationAmount: formData.ensilationAmount,
      ensilationUnit: formData.ensilationUnit,
      drymatter: formData.drymatter,
      weight: formData.weight
    }
  };
}
export function template3(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName
    }
  };
}
export function template4(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName
    }
  };
}
export function template5(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName,
      unit: formData.unit
    }
  };
}
export function template6(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName,
      comboSowingUnit: formData.comboSowingUnit,
      comboFertiUnit: formData.comboFertiUnit
    }
  };
}
export function template7(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName,
      //planAmount: formData.planAmount === "" ? null : formData.planAmount,
      //planUnit: formData.planUnit
    }
  };
}
export function template8(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName
    }
  };
}