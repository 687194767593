import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Header from 'mui-mobile-components/header';
import MapBox from 'mui-mobile-components/kart/mapcomponent/mapbox';
import * as fieldActions from 'actions/Fields';
import { SEARCH_API_KEY } from 'constants/index';
import { Box } from '@mui/material'
import * as getUserActivitiesAction from 'actions/UserActivities';
import AddTaskModal from 'mui-mobile-components/kart/fieldmap/AddTaskModal';
import * as baleFeedEventActions from 'actions/BaleFeedEvent';
import * as taskActions from 'actions/Tasks';
import Snackbar from '@mui/material/Snackbar';
import { template1, template2, template3, template4, template5, template6, template7, template8 } from 'mui-mobile-components/kart/fieldmap/dynamicFields';
import * as Seasons from 'actions/Seasons';
import mixpanel from 'mixpanel-browser';
import { KART_CANCEL_MAP_TASK, KART_SAVE_MAP_TASK} from 'constants/MixPanel';
import * as taskKartToggleAction from 'actions/TaskKartToggle';
import * as ManureAction from 'actions/ManureStorage';
import _ from 'lodash';


class FieldMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinatesState: null,
      onCreateTask: false,
      selectedActivity: [],
      selectedFields: [],
      createdVehicleId: null,
      createdEquipmentId: null,
      showSnackBar: false
    };
    this.openNewTask = this.openNewTask.bind(this);
    this.closeNewTask = this.closeNewTask.bind(this);
    this.saveNewTask = this.saveNewTask.bind(this);
  }

  componentDidMount() {
    const { fetchFieldList1, activeFarm, history, getUserActivities, getLocations, userInfo, getAllAssets, seasons, seasonsList, fetchManureList } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm) {
      fetchManureList(activeFarm.farm.id);
      fetchFieldList1(activeFarm.farm.id);
      getUserActivities(activeFarm.farm.id);
      getLocations(activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'vehicle', activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'equipment', activeFarm.farm.id);
      const fetchCoordinates = async () => {
        let fullAddress  = activeFarm.farm.address + " " + activeFarm.farm.postalCode + " " + activeFarm.farm.region;
        const coords = await this.getCoordinates(fullAddress);
        if (coords) {
          let coordinates = [];
          coordinates.push(coords[1]);
          coordinates.push(coords[0]);
          this.setState({
            coordinatesState: coordinates
          });
        } else {
          this.setState({
            coordinatesState: [8.4689, 60.4720]
          });
        }
      };
      fetchCoordinates();
    }
    seasons.seasonsList = null;
    seasonsList();
  }

  componentDidUpdate(prevProps) {
    const { history, activeFarm, fetchFieldList1, getUserActivities, getLocations, userInfo, getAllAssets, taskDetails, 
      nullifyCreateAssetEntitySuccess, fetchManureList, taskKartToggle } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      fetchManureList(activeFarm.farm.id);
      fetchFieldList1(activeFarm.farm.id);
      getUserActivities(activeFarm.farm.id);
      getLocations(activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'vehicle', activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'equipment', activeFarm.farm.id);
      this.setState({
        coordinatesState: null
      });
    }
    if (this.state.coordinatesState === null) {
      const fetchCoordinates = async () => {
        let fullAddress  = activeFarm.farm.address + " " + activeFarm.farm.postalCode + " " + activeFarm.farm.region;
        const coords = await this.getCoordinates(fullAddress);
        if (coords) {
          let coordinates = [];
          coordinates.push(coords[1]);
          coordinates.push(coords[0]);
          this.setState({
            coordinatesState: coordinates
          });
        } else {
          this.setState({
            coordinatesState: [8.4689, 60.4720]
          });
        }
      };
      fetchCoordinates();
    }
    if(taskDetails.createAssetEntityIsSuccess) {
      getAllAssets(userInfo.data.idToken, 'vehicle', activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'equipment', activeFarm.farm.id);
      if(taskDetails?.createAssetEntityData?.data?.asset?.type.typeId === 'type1') {
        this.setState({
          createdVehicleId: taskDetails?.createAssetEntityData?.data?.asset?.assetId,
        });
      } else {
        this.setState({
          createdEquipmentId: taskDetails?.createAssetEntityData?.data?.asset?.assetId
        });
      }
      nullifyCreateAssetEntitySuccess();
    }
    if(taskDetails.isSuccess) {
      getUserActivities(activeFarm.farm.id);
      this.setState({
        onCreateTask: false,
        createdVehicleId: null,
        createdEquipmentId: null,
        showSnackBar: true
      });
      nullifyCreateAssetEntitySuccess();
    }
    if (taskKartToggle !== prevProps.taskKartToggle) {
      const createdVehicle = taskKartToggle?.getTaskList?.vehicleAssetId;
      const createdEquipment = taskKartToggle?.getTaskList?.equipmentAssetId;
  
      if (createdVehicle) {
        this.setState({
          createdVehicleId: createdVehicle,
          createdEquipmentId: createdEquipment
        });
      }
    }
  }

  getCoordinates = async (address) => {
    const apiKey = SEARCH_API_KEY;
    const encodedAddress = encodeURIComponent(address);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`;
  
    try {
      const response = await axios.get(url);
      const { results } = response.data;

      if (results.length > 0) {
        let arrayCoord = [];
        arrayCoord.push(results[0].geometry.location.lat);
        arrayCoord.push(results[0].geometry.location.lng);
        return arrayCoord;
      } else {
        // Return null if no results are found
        return null;
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      return null;
    }
  };

  openNewTask = (activity, selectedPolygonId, clickedPolygonName) => {
    const newField = { 'fieldId': selectedPolygonId, 'fieldName': clickedPolygonName };
    const { getKartTaskField, activeFarm } = this.props;
    getKartTaskField(activeFarm.farm.id, activity.id, [selectedPolygonId]);
    this.setState((prevState) => ({
      onCreateTask: true,
      selectedActivity: activity,
      //selectedFields: [...prevState.selectedFields, newField]
      selectedFields: [newField]
    }));
  }

  closeNewTask = () => {
    const { getUserActivities, userInfo, activeFarm } = this.props;
    getUserActivities(activeFarm.farm.id);
    mixpanel.identify(userInfo.data.id);
    mixpanel.track(KART_CANCEL_MAP_TASK);
    this.setState({
      createdVehicleId: "",
      createdEquipmentId: "",
      onCreateTask: false
    });
  }

  addNewField = (obj, fieldName, fieldValue) => {
    return {
      ...obj,
      [fieldName]: fieldValue
    };
  }

  saveNewTask = (formData) => {
    const { createTask, activeFarm, taskDetails, userInfo } = this.props;
    if(formData.activityId === 'act11' || formData.activityId === 'act12') {
      formData = template1(formData);
    }
    if(formData.activityId === 'act9' || formData.activityId === 'act10' || formData.activityId === 'act28') {
      formData = template2(formData);
    }
    if(formData.activityId === 'act5' || formData.activityId === 'act14' || formData.activityId === 'act15' || formData.activityId === 'act16' || 
       formData.activityId === 'act17' || formData.activityId === 'act18') {
      formData = template3(formData);
    }
    if(formData.activityId === 'act13') {
      formData = template4(formData);
    }
    if(formData.activityId === 'act1') {
      formData = template5(formData);
    }
    if(formData.activityId === 'act20') {
      formData = template6(formData);
    }
    if(formData.activityId === 'act7' || formData.activityId === 'act31' || formData.activityId === 'act32') {
      formData = template7(formData);
    }
    if(formData.activityId === 'act30') {
      formData.subType = null;
      formData = template7(formData);
    }
    if(formData.activityId === 'act2' || formData.activityId === 'act4' || formData.activityId === 'act8' || formData.activityId === 'act21' || 
      formData.activityId === 'act22' || formData.activityId === 'act23' || formData.activityId === 'act24' || formData.activityId === 'act25' || 
      formData.activityId === 'act26' || formData.activityId === 'act27' || formData.activityId === 'act29') {
      formData = template8(formData);
    }
    let assetVehicleId = "";
    if(formData['vehicleAssetId']) {
      assetVehicleId = formData['vehicleAssetId'];
    } else {
      assetVehicleId = this.state.createdVehicleId;
    }
    if(assetVehicleId !== "" && assetVehicleId !== null) {
      const selecteItem1 = _.filter(taskDetails?.allVehicles, ['asset.assetId', assetVehicleId]);
      if (selecteItem1.length > 0) {
        formData = this.addNewField(formData, "vehicleAssetAssignmentId", selecteItem1[0].assetAssignmentId);
        formData['vehicleAssetId'] = assetVehicleId;
      }
    }

    let assetEquipmentId = "";
    if(formData['equipmentAssetId']) {
      assetEquipmentId = formData['equipmentAssetId'];
    } else {
      assetEquipmentId = this.state.createdEquipmentId;
    }
    if(assetEquipmentId !== "" && assetEquipmentId !== null) {
      const selecteItem2 = _.filter(taskDetails?.allEquipments, ['asset.assetId', assetEquipmentId]);
      if (selecteItem2.length > 0) {
        formData = this.addNewField(formData, "equipmentAssetAssignmentId", selecteItem2[0].assetAssignmentId);
        formData['equipmentAssetId'] = assetEquipmentId;
      }
    }
    mixpanel.identify(userInfo.data.id);
    mixpanel.track(KART_SAVE_MAP_TASK);
    createTask(formData, 'create', true, activeFarm.farm.id, "complete");
  }

  handleSnackbarClose = () => {
    this.setState({
      showSnackBar: false,
    });
  };

  render() {
    const { intl, activeFarm, fieldDetails, geoLocDetails, userActivities, locations, taskDetails, assetsDetails, seasons, activitiesCommon, 
      userInfo, history, manureStorage, taskKartToggle } = this.props;
    return (
      <Box className={`${'main-content'}`}>
        <Header bcTitle={intl.formatMessage({ id: 'KART_BETA' })}/>
        {fieldDetails.data1 && fieldDetails.data1.fields  &&
        <Box style={{backgroundColor: "#FFFFFF", padding: '0px 20px 30px 20px'}}>
          {this.state.coordinatesState !== null &&
          <MapBox
            activeFarm={activeFarm}
            fieldDetails={fieldDetails}
            geoLocDetails={geoLocDetails}
            coordinatesState={this.state.coordinatesState}
            userActivitiesList={userActivities.userActivitiesList}
            openNewTask={this.openNewTask}
            userInfo={userInfo}
            intl={intl}
            history={history}
          />}
        </Box>}
        <AddTaskModal
          intl={intl}
          activeFarm={activeFarm}
          onCreateTask={this.state.onCreateTask}
          selectedFields={this.state.selectedFields}
          selectedActivity={this.state.selectedActivity}
          locations={locations}
          taskDetails={taskDetails}
          closeNewTask={this.closeNewTask}
          saveNewTask={this.saveNewTask}
          assetsDetails={assetsDetails}
          createdVehicleId={this.state.createdVehicleId}
          createdEquipmentId={this.state.createdEquipmentId}
          seasons={seasons}
          activitiesCommon={activitiesCommon}
          manureStorage={manureStorage}
          taskKartToggle={taskKartToggle}
        />
        <Snackbar
          open={this.state.showSnackBar}
          autoHideDuration={3000}
          onClose={this.handleSnackbarClose}
          message={`${taskDetails && taskDetails.data && taskDetails.data[0].taskActivity && taskDetails.data[0].taskActivity.activityName} registrert`}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{
            '& .MuiSnackbarContent-root': {
              display: 'inline-flex',
              justifyContent: 'center',
              width: 'auto',
              minWidth: 'fit-content',
            },
          }}
        />
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  fieldDetails: state.fieldDetails,
  geoLocDetails: state.geoLocDetails.data,
  userActivities: state.userActivities,
  locations: state.baleFeedEvent.locationData,
  userInfo: state.userDetails,
  taskDetails: state.taskDetails,
  assetsDetails: state.assetsDetails,
  seasons: state.seasons,
  activitiesCommon: state.masterData.data[0]?.activities,
  manureStorage: state.manureStorage,
  taskKartToggle: state.taskKartToggle
});

function mapDispatchToProps(dispatch) {
  return {
    fetchFieldList1: farmId => dispatch(fieldActions.fetchFieldList1(farmId)),
    getUserActivities: (farmId) => dispatch(getUserActivitiesAction.getUserActivities(farmId)),
    getLocations: (farmId) => dispatch(baleFeedEventActions.getLocations(farmId)),
    getAllAssets: (token, category, farmId) => dispatch(taskActions.getAllAssets(token, category, farmId)),
    createTask: (taskData, type, taskComplete, farmId, status) => dispatch(taskActions.createUpdateTask(taskData, type, taskComplete, farmId, status, false, 4)),
    nullifyCreateAssetEntitySuccess: () => dispatch(taskActions.nullifyCreateAssetEntitySuccess()),
    seasonsList: () => dispatch(Seasons.seasonsList()),
    fetchManureList: farmId => dispatch(ManureAction.fetchManureList(farmId)),
    getKartTaskField: (farmId, activityId, fieldIds) => dispatch(taskKartToggleAction.getTaskKartToggleField(farmId, activityId, fieldIds))
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(FieldMap)));