import { KART_URL_V4 } from 'constants/ApiUrl';
import {
  SAVE_TASK_KART_TOGGLE_LOADING,
  SAVE_TASK_KART_TOGGLE_SUCCESS,
  SAVE_TASK_KART_TOGGLE_FAILURE,
  GET_TASK_KART_TOGGLE_LOADING,
  GET_TASK_KART_TOGGLE_SUCCESS,
  GET_TASK_KART_TOGGLE_FAILURE
} from 'constants/store/UserActivities';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

export const updateTaskKartToggle = (farmId, activityId, data) => async (dispatch, getState) => {
  dispatch({ type: SAVE_TASK_KART_TOGGLE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${KART_URL_V4}/${farmId}/activityDefaults/${activityId}?language=${GetLanguageCode(
        preferredLanguage
      )}`,
      method: 'post',
      data
    })
  )
  .then(response => {
    dispatch({ type: SAVE_TASK_KART_TOGGLE_SUCCESS, payload: response.data });
  })
  .catch(error => {
    dispatch({ type: SAVE_TASK_KART_TOGGLE_FAILURE, payload: error });
  });
};

export const getTaskKartToggle = (farmId, activityId) => async (dispatch, getState) => {
  dispatch({ type: GET_TASK_KART_TOGGLE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${KART_URL_V4}/${farmId}/activityDefaults/${activityId}?language=${GetLanguageCode(
        preferredLanguage
      )}`,
      method: 'get'
    })
  )
  .then(response => {
    dispatch({ type: GET_TASK_KART_TOGGLE_SUCCESS, payload: response.data });
  })
  .catch(error => {
    dispatch({ type: GET_TASK_KART_TOGGLE_FAILURE, payload: error });
  });
};

export const getTaskKartToggleField = (farmId, activityId, fieldIds) => async (dispatch, getState) => {
  dispatch({ type: GET_TASK_KART_TOGGLE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${KART_URL_V4}/${farmId}/activityDefaults/${activityId}/fields?fieldIds=${fieldIds}&language=${GetLanguageCode(
        preferredLanguage
      )}`,
      method: 'get'
    })
  )
  .then(response => {
    dispatch({ type: GET_TASK_KART_TOGGLE_SUCCESS, payload: response.data });
  })
  .catch(error => {
    dispatch({ type: GET_TASK_KART_TOGGLE_FAILURE, payload: error });
  });
};