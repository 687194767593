import React from 'react';
import Slider from 'rc-slider';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as taskActions from 'actions/Tasks';

const { Handle } = Slider;

const handle = props => {
  const { value, dragging, ...restProps } = props;
  return <Handle value={value} {...restProps} />;
};

class TaskSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVal: 1,
      paramId: props.match.params.id
    };
  }

  componentDidMount() {
    const { editTaskDetails, name } = this.props;
    const { paramId } = this.state;
    if (editTaskDetails && paramId !== 'create') {
      const { isGetTaskData } = editTaskDetails;
      if (isGetTaskData && isGetTaskData.taskActivity) {
        const dynamicDataObj = isGetTaskData.taskActivity;
        if (name === 'TRW') {
          this.setState({ currentVal: dynamicDataObj.windSpeed || 1 });
        } else if (name === 'PJEA') {
          this.setState({ currentVal: dynamicDataObj.effectApplication || 1 });
        }
      }
    }
  }

  handleSliderChange = value => {
    const { name, savetaskInfo } = this.props;
    this.setState({ currentVal: value });
    if (name === 'TRW') {
      savetaskInfo({ wind: value });
    } else if (name === 'PJEA') {
      savetaskInfo({ eoa: value });
    }
  };

  render() {
    const { currentVal } = this.state;
    const { heading, customStyle, name, intl } = this.props;
    return (
      <div className={`slider ${customStyle}`}>
        <div className="slider-heading">
          <p>
            {intl.formatMessage({ id: `${heading}` })}
            {name !== 'PJEA' && (
              <span className="units">
                <FormattedMessage id="CT_W_U" />
              </span>
            )}
          </p>
          <p>
            {name === 'PJEA' && (
              <span className="measure">
                {currentVal <= 7 && <FormattedMessage id="EFFECT_SMALL" />}
                {currentVal > 7 && currentVal <=14 && <FormattedMessage id="EFFECT_MEDIUM" />}
                {currentVal > 14 && <FormattedMessage id="EFFECT_LARGE" />}
              </span>
            )}
            <span className="selected-value">{currentVal}</span>
          </p>
        </div>
        <Slider
          min={1}
          max={20}
          defaultValue={currentVal}
          handle={handle}
          onChange={this.handleSliderChange}
          marks={{ 1: 1, 20: 20 }}
          value={currentVal}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  savetaskInfo: obj => dispatch(taskActions.savetaskInfo(obj))
});

const mapStateToProps = state => ({
  //editTaskObj: state.taskDetails.editTaskObj
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TaskSlider));
