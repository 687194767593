import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { CLEAR_STORE } from 'constants/index';
import Login from 'reducers/Login';
import AWSLogin from 'reducers/AWSLogin';
import ProfileImage from 'reducers/ProfileImage';
import Fields from 'reducers/Fields';
import ReportsReducer from 'reducers/Reports';
import GeoLocReducer from 'reducers/Geolocation';
import NavToggle from 'reducers/NavToggle';
import UsersReducer from 'reducers/Users';
import FarmList from 'reducers/Farms';
import SoundFiles from 'reducers/SoundFiles';
import GpsData from 'reducers/GpsData';
import ActivityList from 'reducers/ActivityList';
import RolesDetails from 'reducers/RolesDetails';
import TasksReducer from 'reducers/Tasks';
import GetTaskReducer from 'reducers/GetTask';
import TaskList from 'reducers/TaskList';
import TermsAndCondition from 'reducers/TermsAndCondition';
import NetworkStatus from 'reducers/NetworkCheck';
import Assets from 'reducers/Assets';
import Stats from 'reducers/Stats';
import MasterListReducer from 'reducers/MasterData';
import ManureStorage from 'reducers/ManureStorage';
import ManureNames from 'reducers/ManureNames';
import SoilSamples from 'reducers/SoilSamples';
import FertilizationPlan from 'reducers/FertilizationPlan';
import MineralFertilizer from 'reducers/MineralFertilizer';
import Seasons from 'reducers/Seasons';
import FieldMethod from 'reducers/FieldMethod';
import FieldChart from 'reducers/FieldChart';
import FieldMasterData from 'reducers/FieldMasterData';
import YieldPlan from 'reducers/YieldPlan';
import ExportShape from 'reducers/ExportShape';
import Bale from 'reducers/Bale';
import BaleFeedEvent from 'reducers/BaleFeedEvent';
import BaleAnalysis from 'reducers/BaleAnalysis';
import SchedulerBale from 'reducers/SchedulerBale';
import GrassAnalysis from 'reducers/GrassAnalysis';
import Regulations from 'reducers/Regulations';
import SupportAdminRole from 'reducers/SupportAdminRole';
import HistoricalData from 'reducers/HistoricalData';
import FieldSoilSamples from 'reducers/FieldSoilSamples';
import HistoricalList from 'reducers/HistoricalList';
import TaskBatch from 'reducers/TaskBatch';
import MineralFertilizerStorage from 'reducers/MineralFertilizerStorage';
import Subscription from 'reducers/Subscription';
import UserActivities from 'reducers/UserActivities';
import KartToggle from 'reducers/KartToggle';
import TaskKartToggle from 'reducers/TaskKartToggle';

const appReducer = combineReducers({
  form: formReducer,
  userDetails: Login,
  AWSLogin,
  profileImage: ProfileImage,
  NavToggle,
  farmList: FarmList,
  fieldDetails: Fields,
  reportDetails: ReportsReducer,
  geoLocDetails: GeoLocReducer,
  farmUserDetails: UsersReducer,
  termsDetails: TermsAndCondition,
  networkStatus: NetworkStatus,
  assetsDetails: Assets,
  statsDetails: Stats,
  masterData: MasterListReducer,
  SoundFiles,
  GpsData,
  ActivityList,
  RolesDetails,
  taskDetails: TasksReducer,
  getTask: GetTaskReducer,
  TaskList,
  manureStorage: ManureStorage,
  manureNames: ManureNames,
  soilSamples: SoilSamples,
  fertilizationPlan: FertilizationPlan,
  mineralFertilizer: MineralFertilizer,
  seasons: Seasons,
  fieldMethod: FieldMethod,
  fieldChart: FieldChart,
  fieldMasterData: FieldMasterData,
  yieldPlan: YieldPlan,
  historicalData: HistoricalData,
  exportShape: ExportShape,
  bales: Bale,
  baleFeedEvent: BaleFeedEvent,
  baleAnalysis: BaleAnalysis,
  schedulerBale: SchedulerBale,
  grassAnalysis: GrassAnalysis,
  regulations: Regulations,
  SupportAdminRole,
  fieldSoilSamples: FieldSoilSamples,
  historicalList: HistoricalList,
  taskBatch: TaskBatch,
  mineralFertilizerStorage: MineralFertilizerStorage,
  subscription: Subscription,
  userActivities: UserActivities,
  kartToggle: KartToggle,
  taskKartToggle: TaskKartToggle
});

export default (state, action) => {
  if (action.type === CLEAR_STORE) {
    const retainState = appReducer(undefined, {});
    retainState.form = state.form;
    return retainState;
  }
  return appReducer(state, action);
};
