import React, { useState, useRef, useEffect } from "react";
import { Typography, Box, Chip, Dialog } from '@mui/material';
import MuiChipMobileDropdown from 'components/mui3-forms/MuiChipMobileDropdown';
import MuiDatePicker from 'components/mui3-forms/MuiDatePicker';
import MuiTextField from 'components/mui3-forms/MuiTextField';
import MuiTextFieldArea from 'components/mui3-forms/MuiTextFieldArea';
import MuiSelectMobileDropdown from 'components/mui3-forms/MuiSelectMobileDropdown';
import MuiCollapseComponent from 'components/mui3-forms/MuiCollapseComponent';
import * as taskTemplateStyle from 'mui-mobile-components/kart/fieldmap/tasktemplates/TaskTemplateStyle';
import AddVehicleModal from 'mui-mobile-components/kart/fieldmap/vehicleModals/AddVehicleModal';
import AddEquipmentModal from 'mui-mobile-components/kart/fieldmap/vehicleModals/AddEquipmentModal';
import AddIcon from '@mui/icons-material/Add';
import { Close as CloseIcon } from '@mui/icons-material';
import _ from 'lodash';
import {
  ACT7,
  PESTICIDE_MINERAL_FERTILIZER_EN,
  PESTICIDE_MINERAL_FERTILIZER_NB,
  PESTICIDE_FOILAR_FERTILIZER_EN,
  PESTICIDE_FOILAR_FERTILIZER_NB,
  PESTICIDE_LIMING_EN,
  PESTICIDE_LIMING_NB,
  PESTICIDE_MANURE_EN,
  PESTICIDE_MANURE_NB
} from 'constants/index';

const AddTaskTemplate7 = (props) => {
  const {intl, activeFarm, selectedFields, selectedActivity, taskDetails, closeNewTask, saveNewTask,
    createdVehicleId, createdEquipmentId, seasons, activitiesCommon, manureStorage, taskKartToggle
  } = props;
  const initialFormState = {
    farmId: activeFarm.farm.id,
    activityId: selectedActivity?.id,
    fieldIds: [selectedFields[0]?.fieldId],
    activityName: selectedActivity?.activityName,
    season: "",
    startTime: new Date(new Date().setHours(0, 0, 0, 0)),
    amount: "",
    type: "",
    subType: "",
    planAmount: "",
    planUnit: "",
    note: "",
    vehicleAssetId: "",
    equipmentAssetId: "",
    waterJournal: null,
    waterJournalAdded: false,
    manureStorageId: null,
    manureStorageName: null
  };
  const [formState, setFormState] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const initialStateRef = useRef(initialFormState);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showAddVehicleDialog, setShowAddVehicleDialog] = useState(false);
  const [showAddEquipmentDialog, setShowAddEquipmentDialog] = useState(false);
  const [subTypeOptions, setSubTypeOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [selectedPlanUnit, setSelectedPlanUnit] = useState([]);

  useEffect(() => {
    if(selectedActivity.id === 'act7') {
      setSubTypeOptions(mineralFertilizerOptions());
      setUnitOptions(mineralFertilizerUnitOptions());
      setSelectedPlanUnit('kg/daa');
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          planUnit: 'kg/daa',
          type: PESTICIDE_MINERAL_FERTILIZER_EN
        };
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
    }
    if(selectedActivity.id === 'act30') {
      setSubTypeOptions(manureOptions());
      setUnitOptions(manourUnitOptions());
      setSelectedPlanUnit('m^3');
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          planUnit: 'm^3',
          type: PESTICIDE_MANURE_EN
        };
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
    }
    if(selectedActivity.id === 'act31') {
      setSubTypeOptions(fertilizerSubTypeLimingOptions());
      setUnitOptions(limingUnitOptions());
      setSelectedPlanUnit('kg/daa');
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          planUnit: 'kg/daa',
          type: PESTICIDE_LIMING_EN
        };
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
    }
    if(selectedActivity.id === 'act32') {
      setSubTypeOptions(fertilizerSubTypeFoilerDataOptions());
      setUnitOptions(foilerFertilizerUnitOptions());
      setSelectedPlanUnit('ml/daa');
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          planUnit: 'ml/daa',
          type: PESTICIDE_FOILAR_FERTILIZER_EN
        };
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
    }
    if (taskKartToggle?.getTaskList?.taskActivity) {
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          vehicleAssetId: taskKartToggle.getTaskList.vehicleAssetId,
          equipmentAssetId: taskKartToggle.getTaskList.equipmentAssetId
        };
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
      if(selectedActivity.id === 'act7') {
        setFormState((prevState) => {
          const updatedFormState1 = {
            ...prevState,
            planAmount: taskKartToggle?.getTaskList?.taskActivity.planAmount ? (taskKartToggle?.getTaskList?.taskActivity.planAmount).toFixed(1) : '',
            planUnit: taskKartToggle?.getTaskList?.taskActivity.planUnit || 'kg/daa',
            subType: taskKartToggle?.getTaskList?.taskActivity.subType || ''
          };
          initialStateRef.current = updatedFormState1;
          return updatedFormState1;
        });
        setSelectedPlanUnit(taskKartToggle?.getTaskList?.taskActivity.planUnit);
      }
      if(selectedActivity.id === 'act30') {
        setFormState((prevState) => {
          const updatedFormState2 = {
            ...prevState,
            planAmount: taskKartToggle?.getTaskList?.taskActivity.planAmount ? (taskKartToggle?.getTaskList?.taskActivity.planAmount).toFixed(1) : '',
            planUnit: taskKartToggle?.getTaskList?.taskActivity.planUnit || 'm^3',
            subType: taskKartToggle?.getTaskList?.taskActivity.manureStorageId || '',
            manureStorageId: taskKartToggle?.getTaskList?.taskActivity.manureStorageId || null,
            manureStorageName: taskKartToggle?.getTaskList?.taskActivity.manureStorageName || null
          };
          initialStateRef.current = updatedFormState2;
          return updatedFormState2;
        });
        setSelectedPlanUnit(taskKartToggle?.getTaskList?.taskActivity.planUnit);
      }
    }
  }, [taskKartToggle]);

  const seasonOptions = () => {
    let seasonOption = [];
    if (seasons && seasons.seasonsList) {
      (seasons.seasonsList).map(data => (
        seasonOption.push({
          value: data.id,
          label: data.season
        })
      ));
    }
    return seasonOption;
  };

  const fertilizerSubTypeFoilerDataOptions = () => {
    let fertilizerSubTypeFoilerDataOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_FOILAR_FERTILIZER_EN || elements.element === PESTICIDE_FOILAR_FERTILIZER_NB) &&
      elements.leaves.map(options => (
        fertilizerSubTypeFoilerDataOptionsArray.push({ label: options, value: options })
      ))
    ))
    return fertilizerSubTypeFoilerDataOptionsArray;
  }

  const fertilizerSubTypeLimingOptions = () => {
    let fertilizerSubTypeLimingOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_LIMING_EN || elements.element === PESTICIDE_LIMING_NB) &&
      elements.leaves.map(options => (
        fertilizerSubTypeLimingOptionsArray.push({ label: options, value: options })
      ))
    ))
    return fertilizerSubTypeLimingOptionsArray;
  }

  const manureOptions = () => {
    return manureStorage?.manureList?.map(option => ({
      label: option.name,
      value: option.id,
    })) || [];
  };
  
  const mineralFertilizerOptions = () => {
    const responseMap = taskKartToggle?.getTaskList?.taskActivity;
    if (responseMap && responseMap.mfList) {
      // Initialize the options array and a Set to track unique entries
      let mineralFertilizerOptionsArray = responseMap.mfList.map(item => ({
        label: item,
        value: item,
      }));
      const uniqueLabels = new Set(mineralFertilizerOptionsArray.map(option => option.label));
      // Add additional options from elements.leaves
      _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.forEach(elements => {
        if (elements.element === PESTICIDE_MINERAL_FERTILIZER_EN || elements.element === PESTICIDE_MINERAL_FERTILIZER_NB) {
          elements.leaves.forEach(options => {
            const label = options;
            if (!uniqueLabels.has(label)) {
              mineralFertilizerOptionsArray.push({ label, value: label });
              uniqueLabels.add(label);
            }
          });
        }
      });
      return mineralFertilizerOptionsArray;
    }
  };

  const manourUnitOptions = () => {
    let manourUnitOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_MANURE_EN || elements.element === PESTICIDE_MANURE_NB) &&
      elements.units.plannedAmountUnit.map(options => (
        manourUnitOptionsArray.push({ label: options, value: options })
      ))
    ))
    return manourUnitOptionsArray;
  }
  
  const foilerFertilizerUnitOptions = () => {
    let foilerFertilizerUnitOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_FOILAR_FERTILIZER_EN || elements.element === PESTICIDE_FOILAR_FERTILIZER_NB) &&
      elements.units.plannedAmountUnit.map(options => (
        foilerFertilizerUnitOptionsArray.push({ label: options, value: options })
      ))
    ))
    return foilerFertilizerUnitOptionsArray;
  }

  const limingUnitOptions = () => {
    let limingUnitOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_LIMING_EN || elements.element === PESTICIDE_LIMING_NB) &&
      elements.units.plannedAmountUnit.map(options => (
        limingUnitOptionsArray.push({ label: options, value: options })
      ))
    ))
    return limingUnitOptionsArray;
  }
  
  const mineralFertilizerUnitOptions = () => {
    let mineralFertilizerUnitOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_MINERAL_FERTILIZER_EN || elements.element === PESTICIDE_MINERAL_FERTILIZER_NB) &&
      elements.units.plannedAmountUnit.map(options => (
        mineralFertilizerUnitOptionsArray.push({ label: options, value: options })
      ))
    ))
    return mineralFertilizerUnitOptionsArray;
  }

  const AddVehicle = () => (
    <Box style={taskTemplateStyle.assetAdd} onClick={() => setShowAddVehicleDialog(true)}>
      <Box sx={{ padding: '5px 15px 0px 0px' }}>
        <AddIcon />
      </Box>
      <Typography style={taskTemplateStyle.assetAddHeader}>{intl.formatMessage({ id: 'CT_ANV' })}</Typography>
    </Box>
  );
  const vehicleOptions = () => {
    let vehicleList = [];
    vehicleList.push({value: null, label: <AddVehicle />});
    if (taskDetails.allVehicles && (taskDetails.allVehicles).length > 0) {
      (taskDetails.allVehicles).map(data => (
        vehicleList.push({
          value: data.asset.assetId,
          label: `${data.asset.brand} ${data.asset.model}`
        })
      ));
      return vehicleList;
    }
    return vehicleList;
  };
  const AddEquipment = () => (
    <Box style={taskTemplateStyle.assetAdd} onClick={() => setShowAddEquipmentDialog(true)}>
      <Box sx={{ padding: '5px 15px 0px 0px' }}>
        <AddIcon />
      </Box>
      <Typography style={taskTemplateStyle.assetAddHeader}>{intl.formatMessage({ id: 'CT_ANE' })}</Typography>
    </Box>
  );
  const equipmentOptions = () => {
    let equipmentList = [];
    equipmentList.push({value: null, label: <AddEquipment />});
    if (taskDetails.allEquipments && (taskDetails.allEquipments).length > 0) {
      (taskDetails.allEquipments).map(data => (
        equipmentList.push({
          value: data.asset.assetId,
          label: `${data.asset.brand} ${data.asset.model}`
        })
      ));
      return equipmentList;
    }
    return equipmentList;
  };

  const handleFieldChange = (fieldName, value) => {
    if (selectedActivity.id === 'act7'){
      const fieldMfMap = taskKartToggle?.getTaskList?.taskActivity?.fieldMfSeasonMap;
      const filteredData = fieldMfMap && fieldMfMap.filter(item => item.fieldId === selectedFields[0]?.fieldId)[0] && fieldMfMap.filter(item => item.fieldId === selectedFields[0]?.fieldId)[0].mfSeasonMap;
      if (filteredData && fieldName === 'subType') {
        let mfSeasonMap = filteredData[formState.season] && (filteredData[formState.season] || null);
        mfSeasonMap = mfSeasonMap && mfSeasonMap.filter(item => item.name === value)[0];
        let planAmountValue = null;
        if(mfSeasonMap && mfSeasonMap.name === value) {
          planAmountValue = mfSeasonMap.quantity || null;
        }
        setFormState((prevState) => {
          const updatedFormState = {
            ...prevState,
            subType: value || null,
            planAmount: planAmountValue % 1 === 0 ? parseInt(planAmountValue, 10) : parseFloat(planAmountValue.toFixed(1)),
            planUnit: taskKartToggle?.getTaskList?.taskActivity.planUnit || 'kg/daa'
          };
          initialStateRef.current = updatedFormState;
          return updatedFormState;
        });
        setSelectedPlanUnit(taskKartToggle?.getTaskList?.taskActivity.planUnit);
      } else if (filteredData && fieldName === 'season') {
        const mfSeasonMap = filteredData[value] && (filteredData[value][0] || null);
        let planAmountValue = null;
        if(mfSeasonMap && mfSeasonMap.name === formState.subType) {
          planAmountValue = mfSeasonMap.quantity || null;
        }
        setFormState((prevState) => {
          const updatedFormState = {
            ...prevState,
            season: value || null,
            planAmount: planAmountValue % 1 === 0 ? parseInt(planAmountValue, 10) : parseFloat(planAmountValue.toFixed(1)),
            planUnit: taskKartToggle?.getTaskList?.taskActivity.planUnit || 'kg/daa'
          };
          initialStateRef.current = updatedFormState;
          return updatedFormState;
        });
        setSelectedPlanUnit(taskKartToggle?.getTaskList?.taskActivity.planUnit);
      } else if (fieldName === 'planUnit') {
        let planAmountValue = formState.planAmount;
        let totalAmountValue = formState.amount;
        if (planAmountValue && taskKartToggle?.getTaskList?.fieldArea) {
          let formStateValue = formState.planAmount
            ? parseFloat(formState.planAmount) *
                (value === "kg"
                  ? parseFloat(taskKartToggle?.getTaskList?.fieldArea)
                  : 1 / parseFloat(taskKartToggle?.getTaskList?.fieldArea))
            : "";
        
          if (formStateValue) {
            planAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
          }
        }
        if (totalAmountValue && taskKartToggle?.getTaskList?.fieldArea) {
          let formStateValue = formState.amount
            ? parseFloat(formState.amount) *
                (value === "kg"
                  ? parseFloat(taskKartToggle?.getTaskList?.fieldArea)
                  : 1 / parseFloat(taskKartToggle?.getTaskList?.fieldArea))
            : "";
          if (formStateValue) {
            totalAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
          }
        }
        setFormState((prevState) => {
          const updatedFormState = {
            ...prevState,
            planUnit: value || null,
            planAmount: planAmountValue,
            amount: totalAmountValue
          };
          initialStateRef.current = updatedFormState;
          return updatedFormState;
        });
        setSelectedPlanUnit(value);
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
      }
    } else if (selectedActivity.id === 'act30'){
      const fieldManureSeasonMap = taskKartToggle?.getTaskList?.taskActivity?.fieldManureSeasonMap;
      const filteredData = fieldManureSeasonMap && fieldManureSeasonMap.filter(item => item.fieldId === selectedFields[0]?.fieldId)[0] && fieldManureSeasonMap.filter(item => item.fieldId === selectedFields[0]?.fieldId)[0].manureSeasonMap;
      const manureStorageList = manureStorage?.manureList;
      const manureName = manureStorageList && manureStorageList.find(item => item.id === value);
      if (filteredData && fieldName === 'subType') {
        const manureSeasonMap = filteredData[formState.season] || null;
        let planAmountValue = null;
        if(manureSeasonMap && manureSeasonMap.storageId === value) {
          planAmountValue = manureSeasonMap.quantity || null;
        }
        setFormState((prevState) => {
          const updatedFormState = {
            ...prevState,
            subType: value || null,
            manureStorageId: value || null,
            manureStorageName: manureName ? manureName.name : null,
            planAmount: planAmountValue % 1 === 0 ? parseInt(planAmountValue, 10) : parseFloat(planAmountValue.toFixed(1)),
            planUnit: taskKartToggle?.getTaskList?.taskActivity.planUnit || 'm^3/daa'
          };
          initialStateRef.current = updatedFormState;
          return updatedFormState;
        });
        setSelectedPlanUnit(taskKartToggle?.getTaskList?.taskActivity.planUnit);
      } else if (filteredData && fieldName === 'season') {
        const manureSeasonMap = filteredData[value] || null;
        let planAmountValue = null;
        if(manureSeasonMap && manureSeasonMap.storageId === formState.subType) {
          planAmountValue = manureSeasonMap.quantity || null;
        }
        setFormState((prevState) => {
          const updatedFormState = {
            ...prevState,
            season: value || null,
            planAmount: planAmountValue % 1 === 0 ? parseInt(planAmountValue, 10) : parseFloat(planAmountValue.toFixed(1)),
            planUnit: taskKartToggle?.getTaskList?.taskActivity.planUnit || 'm^3/daa'
          };
          initialStateRef.current = updatedFormState;
          return updatedFormState;
        });
        setSelectedPlanUnit(taskKartToggle?.getTaskList?.taskActivity.planUnit);
      } else if (fieldName === 'planUnit') {
        let planAmountValue = formState.planAmount;
        let totalAmountValue = formState.amount;
        if (planAmountValue && taskKartToggle?.getTaskList?.fieldArea) {
          let formStateValue = formState.planAmount
            ? parseFloat(formState.planAmount) *
                (value === "m^3"
                  ? parseFloat(taskKartToggle?.getTaskList?.fieldArea)
                  : 1 / parseFloat(taskKartToggle?.getTaskList?.fieldArea))
            : "";
        
          if (formStateValue !== "") {
            planAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
          }
        }
        if (totalAmountValue && taskKartToggle?.getTaskList?.fieldArea) {
          let formStateValue = formState.amount
            ? parseFloat(formState.amount) *
                (value === "m^3"
                  ? parseFloat(taskKartToggle?.getTaskList?.fieldArea)
                  : 1 / parseFloat(taskKartToggle?.getTaskList?.fieldArea))
            : "";
          if (formStateValue) {
            totalAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
          }
        }
        setFormState((prevState) => {
          const updatedFormState = {
            ...prevState,
            planUnit: value || null,
            planAmount: planAmountValue,
            amount: totalAmountValue
          };
          initialStateRef.current = updatedFormState;
          return updatedFormState;
        });
        setSelectedPlanUnit(value);
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
      }
    } else if (selectedActivity.id === 'act31'){
      const fieldManureSeasonMap = taskKartToggle?.getTaskList?.taskActivity;
      if (fieldManureSeasonMap && fieldName === 'planUnit') {
        let planAmountValue = formState.planAmount;
        let totalAmountValue = formState.amount;
        if (planAmountValue && taskKartToggle?.getTaskList?.fieldArea) {
          let formStateValue = formState.planAmount
            ? parseFloat(formState.planAmount) *
                (value === "kg"
                  ? parseFloat(taskKartToggle?.getTaskList?.fieldArea)
                  : 1 / parseFloat(taskKartToggle?.getTaskList?.fieldArea))
            : "";
        
          if (formStateValue !== "") {
            planAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
          }
        }
        if (totalAmountValue && taskKartToggle?.getTaskList?.fieldArea) {
          let formStateValue = formState.amount
            ? parseFloat(formState.amount) *
                (value === "kg"
                  ? parseFloat(taskKartToggle?.getTaskList?.fieldArea)
                  : 1 / parseFloat(taskKartToggle?.getTaskList?.fieldArea))
            : "";
          if (formStateValue) {
            totalAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
          }
        }
        setFormState((prevState) => {
          const updatedFormState = {
            ...prevState,
            planUnit: value || null,
            planAmount: planAmountValue,
            amount: totalAmountValue
          };
          initialStateRef.current = updatedFormState;
          return updatedFormState;
        });
        setSelectedPlanUnit(value);
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
      }
    } else if (selectedActivity.id === 'act32'){
      const fieldManureSeasonMap = taskKartToggle?.getTaskList?.taskActivity;
      if (fieldManureSeasonMap && fieldName === 'planUnit') {
        let planAmountValue = formState.planAmount;
        let totalAmountValue = formState.amount;
        if (planAmountValue && taskKartToggle?.getTaskList?.fieldArea) {
          let formStateValue = formState.planAmount
            ? parseFloat(formState.planAmount) *
                (value === "ml"
                  ? parseFloat(taskKartToggle?.getTaskList?.fieldArea)
                  : 1 / parseFloat(taskKartToggle?.getTaskList?.fieldArea))
            : "";
        
          if (formStateValue !== "") {
            planAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
          }
        }
        if (totalAmountValue && taskKartToggle?.getTaskList?.fieldArea) {
          let formStateValue = formState.amount
            ? parseFloat(formState.amount) *
                (value === "ml"
                  ? parseFloat(taskKartToggle?.getTaskList?.fieldArea)
                  : 1 / parseFloat(taskKartToggle?.getTaskList?.fieldArea))
            : "";
          if (formStateValue) {
            totalAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
          }
        }
        setFormState((prevState) => {
          const updatedFormState = {
            ...prevState,
            planUnit: value || null,
            planAmount: planAmountValue,
            amount: totalAmountValue
          };
          initialStateRef.current = updatedFormState;
          return updatedFormState;
        });
        setSelectedPlanUnit(value);
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const handleNumericValidation = (fieldName, value) => {
    if (/^\d*([.,]?\d*)?$/.test(value)) {
      setFormState((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[fieldName];
        return newErrors;
      });
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: intl.formatMessage({ id: 'DYNAMIC_SECTION_AMOUNT_VAL_MSG' }),
      }));
    }
  };

  const handleSave = () => {
    setModalOpen(false);
    if(formErrors && Object.values(formErrors).length === 0) {
      saveNewTask(formState);
    }
  };

  const handleContinueSave = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    let isChanged = JSON.stringify(initialStateRef.current) !== JSON.stringify(formState);
    if (isChanged) {
      setModalOpen(true);
    } else {
      handleCancelButton();
    }
  };

  const handleCancelButton = () => {
    setModalOpen(false);
    closeNewTask();
  };

  const onCloseVehicleDialog = () => {
    setShowAddVehicleDialog(false);
  }

  const onCloseEquipmentDialog = () => {
    setShowAddEquipmentDialog(false);
  }
  return (
    <Box sx={{
      padding: '0px 16px 0px 16px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '5px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#A8A8A8',
        borderRadius: '4px',
        transition: 'background-color 0.3s ease',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#B8B8B8',
      }}}
    >
      <Box style={taskTemplateStyle.headerSticky}>
        <Box style={taskTemplateStyle.modalDialogHeader}>
          <Box style={taskTemplateStyle.modalDialogHeader1}>
            <Typography sx={{ paddingTop: '5px', paddingRight: '10px' }}>
              <CloseIcon
                width="24"
                height="24"
                onClick={handleCancel}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
            <Typography style={taskTemplateStyle.modalDialogTitle}>{selectedActivity?.activityName}</Typography>
          </Box>
          <Box sx={{ paddingRight: '0px' }}>
            <MuiChipMobileDropdown
              defaultLabel={intl.formatMessage({ id: 'CHOOSE_FERTILIZATION' })}
              textLabel={intl.formatMessage({ id: 'TASK_SEASON' })}
              defaultValue={1}
              options={seasonOptions()}
              optionStyle={taskTemplateStyle.menuOptionMobileTextBig}
              dropdownStyle={taskTemplateStyle.chipDropdownStyleBig}
              fieldName='season'
              onChange={handleFieldChange}
            />
          </Box>
        </Box>
        <Box style={taskTemplateStyle.modalDialogSubHeader}>
          <Typography style={taskTemplateStyle.modalDialogSubTitle}>{selectedFields[0]?.fieldName}</Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '28px' }}>
          <MuiDatePicker
            defaultLabel={intl.formatMessage({ id: 'FIELD_WEATHER_DATE' })}
            dateFormat={"dd/MM/yyyy"}
            fieldName='startTime'
            onChange={handleFieldChange}
            maxDateOption={true}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
          <MuiTextField
            defaultLabel={intl.formatMessage({ id: 'AMOUNT' })}
            defaultValue={formState.amount || ''}
            inputAdornment={`${formState.planUnit === "" || formState.planUnit === undefined || formState.planUnit === null ? '' : formState.planUnit}`}
            fieldName='amount'
            onChange={handleNumericValidation}
            autoFocus={true}
            helperText={formErrors['amount']}
          />
        </Box>
        {/*<Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
          <MuiSelectMobileDropdown
            defaultLabel={intl.formatMessage({ id: 'CT_FERTILIZERTYPE' })}
            options={fertilizerTypeOptions()}
            fieldName="type"
            onChange={handleFieldDropdownChange}
            textLabel={intl.formatMessage({ id: 'CT_FERTILIZERTYPE' })}
            required={false}
            errorMessage={intl.formatMessage({ id: 'required' })}
          />
        </Box>*/}
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
          <MuiSelectMobileDropdown
            defaultLabel={selectedActivity?.id === 'act30' ? intl.formatMessage({ id: 'TASK_REGISTRATION_STORAGE' }) : intl.formatMessage({ id: 'TYPE' })}
            defaultValue={formState.subType}
            options={subTypeOptions}
            fieldName="subType"
            onChange={handleFieldChange}
            textLabel={intl.formatMessage({ id: 'TYPE' })}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '56px' }}>
          <MuiTextFieldArea
            defaultLabel={intl.formatMessage({ id: 'NOTE' })}
            fieldName='note'
            onChange={handleFieldChange}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '40px' }}>
          <MuiCollapseComponent
            title={intl.formatMessage({ id: 'TASK_MORE_INFORMATION' })}
            content={
              <Box sx={{ paddingTop: '48px', width: '100%' }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <MuiTextField
                    defaultLabel={intl.formatMessage({ id: 'PLANNED' })}
                    defaultValue={formState.planAmount || ''}
                    fieldName='planAmount'
                    onChange={handleNumericValidation}
                    autoFocus={false}
                    helperText={formErrors['planAmount']}
                    inputAdornment={`${formState.planUnit === "" || formState.planUnit === undefined || formState.planUnit === null ? '' : formState.planUnit}`}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
                  <MuiSelectMobileDropdown
                    defaultLabel={intl.formatMessage({ id: 'CT_UNIT' })}
                    defaultValue={selectedPlanUnit}
                    options={unitOptions}
                    fieldName="planUnit"
                    onChange={handleFieldChange}
                    textLabel={intl.formatMessage({ id: 'CT_UNIT' })}
                    deSelect={false}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", paddingTop: '56px' }}>
                  <MuiSelectMobileDropdown
                    key={createdVehicleId}
                    defaultLabel={intl.formatMessage({ id: 'VEHICLE' })}
                    defaultValue={createdVehicleId}
                    options={vehicleOptions()}
                    fieldName="vehicleAssetId"
                    onChange={handleFieldChange}
                    textLabel={intl.formatMessage({ id: 'VEHICLE' })}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
                  <MuiSelectMobileDropdown
                    key={createdEquipmentId}
                    defaultLabel={intl.formatMessage({ id: 'EQUIPMENT' })}
                    defaultValue={createdEquipmentId}
                    options={equipmentOptions()}
                    fieldName="equipmentAssetId"
                    onChange={handleFieldChange}
                    textLabel={intl.formatMessage({ id: 'EQUIPMENT' })}
                  />
                </Box>
              </Box>
            }
          />
        </Box>
      </Box>
      <Box style={taskTemplateStyle.buttonBox}>
        <Box style={taskTemplateStyle.buttonBoxBottom}>
          <Chip
            label={intl.formatMessage({ id: 'SAVE' })}
            clickable
            style={taskTemplateStyle.okButtonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={handleSave}
          />
        </Box>
      </Box>
      {/* Modal */}
      <Box>    
        <Dialog onClose={() => {}} open={isModalOpen} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "312px",
            minWidth: "280px",
            maxWidth: "312px",
            maxHeight: '30vh'
          }}}>
          <Box style={taskTemplateStyle.cancelModalBox}>
            <Typography style={taskTemplateStyle.cancelModalTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_TITLE' })}</Typography>
            <Typography style={taskTemplateStyle.cancelModalSubTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_SUB_TITLE' })}</Typography>
          </Box>
          <Box style={taskTemplateStyle.cancelModalBoxButton}>
            <Chip
              label={intl.formatMessage({ id: 'cancel' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{ backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleCancelButton}
            />
            <Chip
              label={intl.formatMessage({ id: 'TASK_CONTINUE_REGISTRATION' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{ backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleContinueSave}
            />
          </Box>
        </Dialog>
      </Box>
      <AddVehicleModal
        intl={intl}
        vehicleSelectedId={'new'}
        onCloseVehicleDialog={onCloseVehicleDialog}
        showAddVehicleDialog={showAddVehicleDialog}
        assetType={'vehicle'}
        taskDetails={taskDetails}
      />
      <AddEquipmentModal
        intl={intl}
        equipmentSelectedId={'new'}
        onCloseEquipmentDialog={onCloseEquipmentDialog}
        showAddEquipmentDialog={showAddEquipmentDialog}
        assetType={'equipment'}
        taskDetails={taskDetails}
      />
    </Box>
  );
};

export default AddTaskTemplate7;