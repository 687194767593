import axios from 'axios';
import {
  API_ENDPOINT,
  API_ENDPOINT_V2,
  CLEAR_STORE,
  LOCAL_KEY_USER,
  ERR_MSG_INVALID_CREDENTIALS,
  FILTER_TASK_FIELD,
  FILTER_TASK_STATUS,
  FILTER_TASK_ACTIVITY,
  REFRESH_TOKEN
} from 'constants/index';
import { AUTH } from 'constants/ApiUrl';
import {
  LOGIN_IS_LOADING,
  LOGIN_FAILURE,
  IS_LOGGED,
  LOGOUT,
  LOGOUT_IS_LOADING,
  //IS_REFRESH_TOKEN_LOADING,
  REFRESH_TOKEN_SUCCESS,
  CLEAR_PROFILE_UPDATE,
  PROFILE_UPDATE_LOADING,
  PROFILE_UPDATE_FALIURE,
  PROFILE_UPDATE_SUCCESS
} from 'constants/store/Auth';
import {
  SetLocalData,
  GetLocalData,
  DeleteLocalData,
  SetSessionData,
  GetSessionData,
  DeleteSessionData
} from 'utils';
//import getAWSToken from 'actions/AWSLogin';
import { getUsers } from 'actions/Users';
import { GAEvent } from 'utils/GoogleAnalytics';
import {
  GA_LOGIN_LBL,
  GA_CAT_ERR,
  GA_SIGN_UP,
  GA_SIGN_UP_ERR,
  GA_LOGIN,
  GA_PROFILE_UPDATE,
  GA_PROFILE_UPDATE_ERR,
  GA_PROFILE_LBL,
  GA_CAT_UPDATE
} from 'constants/GoogleAnalytics';
import { OAUTH_CLIENT_ID, OAUTH_REDIRECT_URI, OAUTH_AUTH_URL, OAUTH_RETURNTO_URL } from 'constants/OauthProperties';
import { REFRESH_TOKEN_MINUTES } from 'constants/Environment';
import { LOGIN, WEB } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';

const searchParams = new URLSearchParams(document.location.search);

const loginHasError = (bool, error) => ({
  type: LOGIN_FAILURE,
  hasError: bool,
  errorMessage: error
});

const loginIsLoading = bool => ({
  type: LOGIN_IS_LOADING,
  isLoading: bool
});

export const loginSuccess = data => ({
  type: IS_LOGGED,
  data
});

export const logOut = data => ({
  type: LOGOUT,
  data
});

const logOutIsLoading = bool => ({
  type: LOGOUT_IS_LOADING,
  isLoading: bool
});

const getAuthToken = refreshTokenDetails => ({
  type: REFRESH_TOKEN_SUCCESS,
  refreshTokenDetails
});

/*const isAuthTokenLoading = bool => ({
  type: IS_REFRESH_TOKEN_LOADING,
  isLoading: bool
});*/

export const clearData = () => ({
  type: CLEAR_STORE
});

export const clearUpdate = () => ({
  type: CLEAR_PROFILE_UPDATE
});

export const profileUpdateLoading = bool => ({
  type: PROFILE_UPDATE_LOADING,
  payload: bool
});

export const profileUpdateSuccess = bool => ({
  type: PROFILE_UPDATE_SUCCESS,
  payload: bool
});
export const profileUpdateFailure = (bool, errorMessage) => ({
  type: PROFILE_UPDATE_FALIURE,
  payload: bool,
  errorMessage
});
/**
 * doLogin dispatch login api details
 * @param {email, password} values
 */
export const doLogin = (values, rememberMe) => async dispatch => {
  try {
    dispatch(loginIsLoading(true));
    let formData = {};
    formData["client_id"] = OAUTH_CLIENT_ID;
    formData["code"] = searchParams.get('code');
    formData["grant_type"] = 'authorization_code';
    formData["code_verifier"] = sessionStorage.getItem("codeVerifier");
    formData["redirect_uri"] = OAUTH_REDIRECT_URI+"/auth";
    const data = Object.keys(formData).map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])).join('&');
      
    const user = await axios(OAUTH_AUTH_URL+"/oauth2/token", {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      data
    });
    let userNewDetails = {};
    if (user.data) {
      const userOtherDetails = await axios(`${API_ENDPOINT}users/details`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: user.data.access_token
        }
      });
      userNewDetails["accessToken"] = user.data.access_token;
      userNewDetails["emailId"] = userOtherDetails.data.emailId;
      userNewDetails["firstName"] = userOtherDetails.data.firstName;
      userNewDetails["phone"] = userOtherDetails.data.phone;
      userNewDetails["id"] = userOtherDetails.data.id;
      userNewDetails["idToken"] = user.data.access_token;
      userNewDetails["lastName"] = userOtherDetails.data.lastName;
      userNewDetails["lastaccessedFarm"] = userOtherDetails.data.lastaccessedFarm;
      userNewDetails["loginDate"] = new Date();
      userNewDetails["preferredLanguage"] = userOtherDetails.data.preferredLanguage;
      userNewDetails["profileImageLocation"] = userOtherDetails.data.profileImageLocation;
      userNewDetails["refreshToken"] = user.data.refresh_token;
      userNewDetails["sessionTime"] = new Date().toISOString();
      userNewDetails["sessionTimeOut"] = new Date(new Date().getTime()+(REFRESH_TOKEN_MINUTES*60000)).toISOString();
      GAEvent(GA_LOGIN, GA_LOGIN_LBL, GA_LOGIN_LBL);
      SetSessionData(LOCAL_KEY_USER, userNewDetails);
      SetLocalData(LOCAL_KEY_USER, userNewDetails);
      SetSessionData(REFRESH_TOKEN, false);
      dispatch(loginSuccess(userNewDetails));
      dispatch(loginIsLoading(false));
      mixpanel.register({
        'Platform':WEB
      });
      mixpanel.people.set({
        "User ID": data.id,
        "$email": data.emailId
      });
      mixpanel.identify(data.id);
      mixpanel.track(LOGIN);
      //DeleteLocalData(LOCAL_FARM_LIST);
      //window.location.replace("/#/tasks");
    } else {
      throw new Error('Something went wrong, Please try again');
    }
  } catch (error) {
      dispatch(loginHasError(true, ERR_MSG_INVALID_CREDENTIALS));
      DeleteLocalData(LOCAL_KEY_USER);
      DeleteSessionData(LOCAL_KEY_USER);
      DeleteSessionData(FILTER_TASK_FIELD);
      DeleteSessionData(FILTER_TASK_STATUS);
      DeleteSessionData(FILTER_TASK_ACTIVITY);
      //DeleteLocalData(LOCAL_FARM_LIST);       
      window.location.replace(`${OAUTH_AUTH_URL}/ory/logout?return_to=${OAUTH_RETURNTO_URL}`);
  }
};
// Logout
export const doLogOut = (accessToken, lastAccessedFarm) => async dispatch => {
  try {
    dispatch(logOutIsLoading(true));
    DeleteLocalData(LOCAL_KEY_USER);
    DeleteSessionData(LOCAL_KEY_USER);
    DeleteSessionData(FILTER_TASK_FIELD);
    DeleteSessionData(FILTER_TASK_STATUS);
    DeleteSessionData(FILTER_TASK_ACTIVITY);
    //DeleteLocalData(LOCAL_FARM_LIST);
    await axios(`${API_ENDPOINT_V2}users/signout`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        accessToken
      },
      data: { lastAccessedFarm }
    });
    window.location.replace(`${OAUTH_AUTH_URL}/ory/logout?return_to=${OAUTH_RETURNTO_URL}`);
  } catch (error) {
    window.location.replace(`${OAUTH_AUTH_URL}/ory/logout?return_to=${OAUTH_RETURNTO_URL}`);
    //dispatch(logOutHasError(true, error));
  }
};

/**
 * Update profile
 * @param {string} emailId
 * @param {string} id
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} profileImageLocation
 * @param {string} preferredLanguage
 */
export const updateProfileInfo = (
  emailId,
  id,
  firstName,
  lastName,
  phone,
  profileImageLocation,
  preferredLanguage
) => async (dispatch, getState) => {
  dispatch(clearUpdate());
  dispatch(profileUpdateLoading(true));
  const stateUserData = getState().userDetails?.data;
  try {
    await axios(AUTH, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        accessToken: stateUserData.accessToken
      },
      data: {
        emailId,
        id,
        firstName,
        lastName,
        phone,
        profileImageLocation,
        preferredLanguage
      }
    })
      .then(response => {
        // Check for local storage data if not store it the session
        const updateUserInfo = response.data;
        const userData = GetLocalData(LOCAL_KEY_USER);
        GAEvent(GA_PROFILE_UPDATE, GA_CAT_UPDATE, GA_PROFILE_LBL);
        if (
          (stateUserData.lastName !== updateUserInfo.lastName ||
            stateUserData.firstName !== updateUserInfo.firstName) &&
          getState().farmList?.activeFarm?.farm.id
        ) {
          dispatch(getUsers(getState().farmList.activeFarm.farm.id));
        }
        if (userData) {
          userData.emailId = updateUserInfo.emailId;
          userData.firstName = updateUserInfo.firstName;
          userData.lastName = updateUserInfo.lastName;
          userData.phone = updateUserInfo.phone;
          userData.preferredLanguage = updateUserInfo.preferredLanguage;
          userData.profileImageLocation = updateUserInfo.profileImageLocation;
          SetLocalData(LOCAL_KEY_USER, userData);

          const sessionUser = GetSessionData(LOCAL_KEY_USER);
          sessionUser.emailId = updateUserInfo.emailId;
          sessionUser.firstName = updateUserInfo.firstName;
          sessionUser.lastName = updateUserInfo.lastName;
          sessionUser.phone = updateUserInfo.phone;
          sessionUser.preferredLanguage = updateUserInfo.preferredLanguage;
          sessionUser.profileImageLocation = updateUserInfo.profileImageLocation;
          SetSessionData(LOCAL_KEY_USER, sessionUser);
          
          dispatch(loginSuccess(userData));
          dispatch(profileUpdateSuccess(true));
        } else {
          const sessionUser = GetSessionData(LOCAL_KEY_USER);
          sessionUser.emailId = updateUserInfo.emailId;
          sessionUser.firstName = updateUserInfo.firstName;
          sessionUser.lastName = updateUserInfo.lastName;
          sessionUser.phone = updateUserInfo.phone;
          sessionUser.preferredLanguage = updateUserInfo.preferredLanguage;
          sessionUser.profileImageLocation = updateUserInfo.profileImageLocation;
          SetSessionData(LOCAL_KEY_USER, sessionUser);
          dispatch(loginSuccess(sessionUser));
          dispatch(profileUpdateSuccess(true));
        }
      })
      .catch(error => {
        GAEvent(GA_PROFILE_UPDATE_ERR, GA_CAT_ERR, GA_PROFILE_LBL);
        dispatch(profileUpdateFailure(true, error));
      });
  } catch (error) {
    dispatch(profileUpdateFailure(true, error));
  }
};

/**
 * doLogin dispatch login api details
 * @param {UserDetails} values
 */
export const getNewLoginToken = userDetails => async dispatch => {
  try {
    //dispatch(isAuthTokenLoading(true));
    let formData = {};
    let url = OAUTH_AUTH_URL+'/oauth2/token';
    formData["client_id"] = OAUTH_CLIENT_ID;
    formData["grant_type"] = 'refresh_token';
    formData["refresh_token"] = userDetails.refreshToken;
    const data = Object.keys(formData).map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])).join('&'); 
    const response = await axios(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      data
    })
    if(response.status === 200) {
      //const userData = userDetails;
      userDetails.loginDate = new Date();
      userDetails.sessionTime = new Date().toISOString();
      userDetails.sessionTimeOut = new Date(new Date().getTime()+(REFRESH_TOKEN_MINUTES*60000)).toISOString();
      userDetails.idToken = response.data.access_token;
      userDetails.accessToken = response.data.access_token;
      userDetails.refreshToken = response.data.refresh_token;
      if (GetLocalData(LOCAL_KEY_USER)) {
        SetLocalData(LOCAL_KEY_USER, userDetails);
      }
      SetSessionData(LOCAL_KEY_USER, userDetails);
      dispatch(loginSuccess(userDetails));
      dispatch(getAuthToken(response.data));
      SetSessionData(REFRESH_TOKEN, false);
    } else {
      dispatch(doLogOut(userDetails.accessToken));
    }
  } catch (error) {
    //dispatch(hasAuthTokenError(error));
    dispatch(doLogOut(userDetails.accessToken));
  }
};

/**
 * New user registration
 * @param {firstName, LastName, emailId, password, callback}
 */
export const userRegistration = (
  firstName,
  lastName,
  phone,
  emailId,
  password,
  callback
) => async dispatch => {
  DeleteLocalData(LOCAL_KEY_USER);
  DeleteSessionData(LOCAL_KEY_USER);
  try {
    await axios(AUTH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        emailId,
        password,
        firstName,
        lastName,
        phone
      }
    })
    .then(response => {
      GAEvent(GA_SIGN_UP, GA_LOGIN_LBL, GA_LOGIN_LBL);
      //dispatch(loginSuccess(response.data));
      //dispatch(getAWSToken(response.data.idToken));
      // storing the user data in session
      //SetLocalData(LOCAL_KEY_USER, response.data);
      //SetSessionData(LOCAL_KEY_USER, response.data);
      callback(response);
    })
    .catch(error => {
      GAEvent(GA_SIGN_UP_ERR, GA_CAT_ERR, GA_LOGIN_LBL);
      callback(error);
    });
  } catch (error) {
    GAEvent(GA_SIGN_UP_ERR, GA_CAT_ERR, GA_LOGIN_LBL);
    callback(error);
  }
};
