import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter, Redirect } from 'react-router-dom';
import { NEW } from 'constants/index';
import * as fieldSoilSampleActions from 'actions/FieldSoilSamples';
import _ from 'lodash';
import { DecimalValidation, IntegerValidation, getYearsField, getSoilTypes, getMoldClass } from 'utils/index';
import Header from 'mui-components/header';

import { Box, Grid, Stack, Button } from '@mui/material'
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import MuiSelectBox from 'components/mui-forms/MuiSelectBox';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import mixpanel from 'mixpanel-browser';
import { ADD_SOIL_SAMPLE_CREATED } from 'constants/MixPanel';

class AddUpdateSoilSample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramId: props.match.params.id,
      soilParamId: props.match.params.soilId,
      year: '',
      soilType: '',
      moldClass: '',
      phosphorus: '',
      potassium: '',
      acidSolublePotassium: '',
      ph: '',
      isView: false,
      defaultInitialize: false
    }
  }

  componentDidMount = () => {
    const { history, activeFarm } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
  };

  componentDidUpdate = (prevProps) => {
    const { history, activeFarm, fieldSoilSamples } = this.props;
    const { defaultInitialize, soilParamId } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    }
    if(!defaultInitialize) {
      const selectedSoilSample = _.filter(fieldSoilSamples.getFieldSoilSampleData, ['id', soilParamId]);
      this.setState({ isView: false });
      if(selectedSoilSample.length > 0) {
        fieldSoilSamples.hasError = false;
        this.setState({
          year: selectedSoilSample[0].year,
          soilType: (selectedSoilSample[0].soilType !== 0 && selectedSoilSample[0].soilType !== null) ? parseInt(selectedSoilSample[0].soilType) : '',
          moldClass: (selectedSoilSample[0].moldClass !== 0 && selectedSoilSample[0].moldClass !== null) ? parseInt(selectedSoilSample[0].moldClass) : '',
          phosphorus: (selectedSoilSample[0].phosphorus !== 0 && selectedSoilSample[0].phosphorus !== null) ? selectedSoilSample[0].phosphorus : '',
          potassium: (selectedSoilSample[0].potassium !== 0 && selectedSoilSample[0].potassium !== null) ? selectedSoilSample[0].potassium : '',
          acidSolublePotassium: (selectedSoilSample[0].acidSolublePotassium !== 0 && selectedSoilSample[0].acidSolublePotassium !== null) ? selectedSoilSample[0].acidSolublePotassium : '',
          ph: (selectedSoilSample[0].pH !== 0 && selectedSoilSample[0].pH !== null) ? selectedSoilSample[0].pH : ''
        });
        if(selectedSoilSample[0].source === 'Importert fra fil') {
          this.setState({
            isView: true
          })
        }
      }
      this.setState({
        defaultInitialize: true
      })
    }
    if(fieldSoilSamples.isSuccess) {
      fieldSoilSamples.isSuccess = false;
      //window.location.href = `#/managefields/${this.props.match.params.id}/soilsamples`;
      history.push(`/managefields/${this.props.match.params.id}/soilsamples`);
    }
  };

  handleSubmit = values => {
    const { activeFarm, addSoilSample, updateSoilSample, userDetails } = this.props;
    const { soilParamId } = this.state;
    let paramId = this.props.match.params.id;
    let formData = {};
    formData["soilType"] = (this.state.soilType !== '' && this.state.soilType !== null) ? this.state.soilType : 0;
    formData["moldClass"] = (this.state.moldClass !== '' && this.state.moldClass !== null) ? this.state.moldClass : 0;
    formData["phosphorus"] = (this.state.phosphorus !== '' && this.state.phosphorus !== null) ? this.state.phosphorus : 0;
    formData["potassium"] = (this.state.potassium !== '' && this.state.potassium !== null) ? this.state.potassium : 0;
    formData["acidSolublePotassium"] = (this.state.acidSolublePotassium !== '' && this.state.acidSolublePotassium !== null) ? this.state.acidSolublePotassium : 0;
    formData["pH"] = (this.state.ph !== '' && this.state.ph !== null) ? this.state.ph : 0;
    formData["year"] = this.state.year;
    if(this.state.soilParamId !=='new') {
      updateSoilSample(activeFarm.farm.id, paramId, formData, soilParamId);
    } else {
      mixpanel.identify(userDetails.id);
      mixpanel.track(ADD_SOIL_SAMPLE_CREATED);
      addSoilSample(activeFarm.farm.id, paramId, formData);
    }
    return;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { intl, fieldDetails, handleSubmit, fieldSoilSamples } = this.props;
    const { paramId, soilParamId } = this.state;
    const centerAlignStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    };
    const customButtonStyle = {
      backgroundColor: '#00696D', color: '#ffffff',
      '&:hover': {
        backgroundColor: '#00696D'
      }, borderRadius: '100px', paddingLeft: 20, paddingRight: 20, textTransform: 'none', fontSize: '14px', fontFamily: 'Inter'
    };
    const currentField = _.filter(fieldDetails, ['fieldId', paramId]);
    return (
      fieldDetails === undefined ? <Redirect to='/managefields'/> :
      (<Box className={`${'main-content'}`}>
        <Box>
          <Header
            bcLink={{ to: '/managefields', text: intl.formatMessage({ id: 'HEADER_FDS' }) }}
            soilLink={{ to: `/managefields/${paramId}/soilsamples`, text: currentField[0].fieldName }}
            bcTitle={
            soilParamId === NEW ? intl.formatMessage({ id: 'create' }) : intl.formatMessage({ id: 'EDIT' })}
            bcLinkArrow={true}
          />
        </Box>
        <Box className="settings-tabs">
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', flexGrow: 1, padding: '30px' }}>
              <Grid container justifyContent="flex-end" sx={{ paddingBottom: '20px', fontFamily: 'Inter', fontSize: '18px' }}>
                {soilParamId === NEW ? intl.formatMessage({ id: 'create' }) : intl.formatMessage({ id: 'EDIT' })}
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item md={4} xs={12} className='grid-with-padding'>
                  <Stack className='text-with-padding'>
                    <MuiSelectBox 
                      name="year"
                      id="year"
                      label={intl.formatMessage({ id: 'YEAR' })}
                      variant="outlined"
                      disabled={this.state.isView}
                      value={this.state.year}
                      options={getYearsField(1990)}
                      onChange={this.handleChange}
                    />
                  </Stack>
                  {this.state.fieldSize}
                  <Stack className='text-with-padding'>
                    <MuiSelectBox 
                      name="soilType"
                      id="soilType"
                      label={intl.formatMessage({ id: 'FIELD_SOIL_TYPE' })}
                      variant="outlined"
                      value={this.state.soilType}
                      options={getSoilTypes()}
                      onChange={this.handleChange}
                    />
                  </Stack>
                  <Stack className='text-with-padding'>
                    <MuiSelectBox 
                      name="moldClass"
                      id="moldClass"
                      label={intl.formatMessage({ id: 'FIELD_MOLD_CLASS' })}
                      variant="outlined"
                      value={this.state.moldClass}
                      options={getMoldClass()}
                      onChange={this.handleChange}
                    />
                  </Stack>
                  <Stack className='text-with-padding'>
                    <MuiTextBox 
                      name="phosphorus"
                      id="phosphorus"
                      label={intl.formatMessage({ id: 'FIELD_PHOSPHORUS' })}
                      variant="outlined"
                      value={this.state.phosphorus}
                      onChange={this.handleChange}
                      showFullWidth="true"
                    />
                  </Stack>
                  <Stack className='text-with-padding'>
                    <MuiTextBox 
                      name="potassium"
                      id="potassium"
                      label={intl.formatMessage({ id: 'FIELD_POTASSIUM' })}
                      variant="outlined"
                      value={this.state.potassium}
                      onChange={this.handleChange}
                      showFullWidth="true"
                    />
                  </Stack>
                  <Stack className='text-with-padding'>
                    <MuiTextBox 
                      name="acidSolublePotassium"
                      id="acidSolublePotassium"
                      label={intl.formatMessage({ id: 'FIELD_ACID_SOLUBLE' })}
                      variant="outlined"
                      value={this.state.acidSolublePotassium}
                      onChange={this.handleChange}
                      showFullWidth="true"
                    />
                  </Stack>
                  <Stack className='text-with-padding'>
                    <MuiTextBox 
                      name="ph"
                      id="ph"
                      label={intl.formatMessage({ id: 'PH_VALUE' })}
                      variant="outlined"
                      value={this.state.ph}
                      onChange={this.handleChange}
                      showFullWidth="true"
                    />
                  </Stack>
                </Grid>
                <Grid item md={8} xs={12}>&nbsp;</Grid>
              </Grid>
            </Box>
            <div className="lightbox-field-padding-top-large" style={{paddingTop: '0px'}}>
              <span className="paddingRight">
                {fieldSoilSamples.hasError && fieldSoilSamples.errorMessage.data.message && <ErrorText>{fieldSoilSamples.errorMessage.data.message}</ErrorText>}
                {fieldSoilSamples.hasError && !fieldSoilSamples.errorMessage.data.message && <ErrorText>{fieldSoilSamples.errorMessage.data}</ErrorText>}
              </span>
            </div>
            <div style={centerAlignStyle}>
              <Button style={customButtonStyle} type="submit" 
                onClick={() => {
                  handleSubmit(this.handleSubmit)
                }}
              >
                {intl.formatMessage({ id: 'AEF_SB' })}
              </Button>
            </div>
          </form>
         </Box>
      </Box>)
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const { intl } = props;

  if (values.soilType) {
    const result = IntegerValidation(values.soilType);
    if (!result.valid || values.soilType < 1) {
      errors.soilType = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.moldClass) {
    const result = DecimalValidation(values.moldClass);
    if (!result.valid || values.moldClass <= 0) {
      errors.moldClass = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.phosphorus) {
    const result = DecimalValidation(values.phosphorus);
    if (!result.valid || values.phosphorus <= 0) {
      errors.phosphorus = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.potassium) {
    const result = DecimalValidation(values.potassium);
    if (!result.valid || values.potassium <= 0) {
      errors.potassium = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.acidSolublePotassium) {
    const result = DecimalValidation(values.acidSolublePotassium);
    if (!result.valid || values.acidSolublePotassium <= 0) {
      errors.acidSolublePotassium = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.ph) {
    const result = DecimalValidation(values.ph);
    if (values.ph > 14) {
      errors.ph = intl.formatMessage({ id: 'INVALID_PH_VALUE' });
    } else if (!result.valid) {
      errors.ph = intl.formatMessage({ id: 'INVALID_PH_VALUE' });
    }
  }
  return errors;
};

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  userDetails: state.userDetails.data,
  fieldSoilSamples: state.fieldSoilSamples,
  hasSupportAdminRole: state.SupportAdminRole,
  fieldDetails: state.fieldDetails.data
});

const mapDispatchToProps = dispatch => ({
  getSoilSamples: (farmId, fieldId) => dispatch(fieldSoilSampleActions.getFieldSoilSamples(farmId, fieldId)),
  addSoilSample: (farmId, fieldId, data) => dispatch(fieldSoilSampleActions.addFieldSoilSamples(farmId, fieldId, data)), 
  updateSoilSample: (farmId, fieldId, data, soilId) => dispatch(fieldSoilSampleActions.updateFieldSoilSamples(farmId, fieldId, data, soilId)),
  deleteSoilSample: (farmId, fieldId, soilId) => dispatch(fieldSoilSampleActions.deleteFieldSoilSamples(farmId, fieldId, soilId))
});

export default injectIntl(
  reduxForm({
    form: 'AddUpdateSoilSampleForm',
    validate
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUpdateSoilSample)))
);
