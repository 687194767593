import { getApiUrl } from "./ApiUrl"

// API end point
export const API_URI = getApiUrl();
export const API_ENDPOINT = `${API_URI}api/v1/`;
export const API_ENDPOINT_V2 = `${API_URI}api/v2/`;
export const API_ENDPOINT_V3 = `${API_URI}api/v3/`;
export const API_ENDPOINT_V4 = `${API_URI}api/v4/`;
export const GET_GEO_LOCATION = 'GET_GEO_LOCATION';

// clear store
export const CLEAR_STORE = 'CLEAR_STORE';

// Reports
export const REPORTS_DOWNLOAD_FAILURE = 'REPORTS_DOWNLOAD_FAILURE';
export const REPORTS_DOWNLOAD_SUCCESS = 'REPORTS_DOWNLOAD_SUCCESS';

// Task Management
export const NULLFIY_SUCCESS = 'NULLFIY_SUCCESS';
export const GET_VEHICLE_LIST = 'GET_VEHICLE_LIST';
export const GET_VEHICLE_LIST_FAILURE = 'GET_VEHICLE_LIST_FAILURE';
export const GET_EQUIPMENT_LIST = 'GET_EQUIPMENT_LIST';
export const GET_EQUIPMENT_LIST_FAILURE = 'GET_EQUIPMENT_LIST_FAILURE';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';
export const STORE_TASK_DETAILS = 'STORE_TASK_DETAILS';
export const GET_ENTITY_DETAILS_SUCCESS = 'GET_ENTITY_DETAILS_SUCCESS';
export const GET_ENTITY_DETAILS_FAILURE = 'GET_ENTITY_DETAILS_FAILURE';
export const CREATE_ASSET_ENTITY_SUCCESS = 'CREATE_ASSET_ENTITY_SUCCESS';
export const CREATE_ASSET_ENTITY_FAILURE = 'CREATE_ASSET_ENTITY_FAILURE';
export const EDIT_TASK_INFO = 'EDIT_TASK_INFO';
export const SUBMIT_TASK_IS_LOADING = 'SUBMIT_TASK_IS_LOADING';
export const GET_ALL_ASSETS = 'GET_ALL_ASSETS';
export const GET_ALL_ASSETS_FAILURE = 'GET_ALL_ASSETS_FAILURE';
export const CREATE_ASSET_ENTITY_IS_LOADING = 'CREATE_ASSET_ENTITY_IS_LOADING';
export const GET_ASSET_ENTITY_IS_LOADING = 'GET_ASSET_ENTITY_IS_LOADING';
export const NULLIFY_CREATE_ASSET_ENTITY_SUCCESS = 'NULLIFY_CREATE_ASSET_ENTITY_SUCCESS';
export const GET_TASK_LIST = 'GET_TASK_LIST';
export const IS_GET_TASK_LIST_FAILURE = 'IS_GET_TASK_LIST_FAILURE';
export const IS_GET_TASK_LIST_LOADING = 'IS_GET_TASK_LIST_LOADING';
export const GET_TASK_USER_PIC = 'GET_TASK_USER_PIC';
export const DELETE_ASSET_IS_LOADING = 'DELETE_ASSET_IS_LOADING';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_FAILURE = 'DELETE_ASSET_FAILURE';
export const TASK_LIST_DATA_RESET = 'TASK_LIST_DATA_RESET';
export const TASK_LIST_DATA_CLEAR = 'TASK_LIST_DATA_CLEAR';

export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const IS_TASK_UPDATE_LOADING = 'IS_TASK_UPDATE_LOADING';
export const TASK_UPDATE_FAILURE = 'TASK_UPDATE_FAILURE';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const IS_DELETE_TASK_LOADING = 'IS_DELETE_TASK_LOADING';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';
export const TASK_SORT_BY_OPTION = 'TASK_SORT_BY_OPTION';
export const TASK_LIST_POSITION = 'TASK_LIST_POSITION';

// Assets
export const VEHICLES = 'vehicles';
export const EQUIPMENTS = 'equipments';
export const VEHICLE = 'vehicle';
export const EQUIPMENT = 'equipment';
export const USERS = 'users';
export const TASKS = 'tasks';
export const MANAGEFIELDS = 'managefields';
export const MANURE_STORAGE = 'manure-storage';
export const FERTILIZATION_PLAN = 'fertilization-plan';
export const SOIL_SAMPLE = 'soil-samples';
export const BALE_STORAGE = 'bale-storage';
export const MINERAL_STORAGE = 'mineral-storage';
export const ASSET_SORT_BY_OPTION = 'ASSET_SORT_BY_OPTION';
export const NULLIFY_ASSET_ENTITY_DETAILS = 'NULLIFY_ASSET_ENTITY_DETAILS';
export const FIELD_WEATHER = 'weather';
export const FIELD_SOIL_SAMPLES = 'soilsamples';
export const FIELD_HISTORICAL = 'historical';
export const FIELD_MANURE = 'manure';
export const FIELD_FERTILIZATION = 'fertilization';
export const FIELD_SOIL_SAMPLES_IMPORT = 'soilsamplesimport';
export const GRASS_ANALYSIS = 'grassanalysis';
export const GRASS_ANALYSIS_SEARCH = 'search';
export const SUBSCRIPTION = 'subscription';
export const KART = 'kart';

// API URL
export const API_GET_FIELDS = `${API_ENDPOINT}farms`;
export const API_DELETE_FARM_FIELD = `${API_ENDPOINT}farms/`;

// User Image s3 bucket path
export const IMG_UPLOAD_FOLDER = 'profile-picture/';
export const IMG_UPLOAD_REQUEST = 'UPLOAD';
export const IMG_DOWNLOAD_REQUEST = 'DOWNLOAD';

// Sound files s3 bucket paths
export const SOUND_FOLDER = 'sound-data/';

// sound files
export const GET_SOUND_FILES_IS_LOADING = 'GET_SOUND_FILES_IS_LOADING';
export const GET_SOUND_FILES_SUCCESS = 'GET_SOUND_FILES_SUCCESS';
export const GET_SOUND_FILES_FAILURE = 'GET_SOUND_FILES_FAILURE';

// Gps data
export const GET_GPS_FARM_DATA = 'GET_GPS_FARM_DATA';
export const IS_GPS_FARM_DATA_LOADING = 'IS_GPS_FARM_DATAT_LOADING';
export const IS_GPS_FARM_DATA_FAILURE = 'IS_GPS_FARM_DATA_FAILURE';

// Data validation permission
export const DATA_PERMISSION_SUCCESS = 'DATA_PERMISSION_SUCCESS';
export const DATA_PERMISSION_LOADING = 'DATA_PERMISSION_LOADING';
export const DATA_PERMISSION_ERROR = 'DATA_PERMISSION_ERROR';

// Map coordinates save
export const SAVE_MAP_COORDINATES = 'SAVE_MAP_COORDINATES';

// Network Check
export const IS_OFFLINE = 'IS_OFFLINE';
export const IS_ONLINE = 'IS_ONLINE';
export const SHOW_NETWORK_ERROR = 'NETWORK_CHECK_ERROR';

// Language options
export const LanguageOptions = [
  { value: 'English', label: 'English' },
  { value: 'Nynorsk', label: 'Nynorsk' },
  { value: 'Bokmål', label: 'Bokmål' }
];

// reports
export const durationOptions = [
  { value: 'year', label: 'Yesterday' },
  { value: 'year', label: 'Last 7 days' },
  { value: 'year', label: 'Last 30 days' },
  { value: 'year', label: 'Last 90 days' },
  { value: 'year', label: 'Last 180 days' },
  { value: 'year', label: 'Last year' }
];

// Local storage data names
export const LOCAL_KEY_USER = 'lud';
export const LOCAL_FARM_LIST ="localFarmList";

// Refresh Token name
export const REFRESH_TOKEN = 'RefreshToken';

// Toggle Nav menu for mobile
export const NAV_MENU_TOGGLE = 'NAV_MENU_TOGGLE';

// Google map api key
export const SEARCH_API_KEY = 'AIzaSyD2jfRjEZ0FUvPSC_godiQ7DliqMdJc8bs';

// API respose code

export const CODE_404 = 404;
export const CODE_422 = 422;
export const CODE_409 = 409;
export const CODE_201 = 201;
export const CODE_204 = 204;
export const ERR_MSG_ACCOUNT_LOCKED = 'ERR_MSG_ACCOUNT_LOCKED';
export const ERR_MSG_INVALID_CREDENTIALS = 'ERR_MSG_INVALID_CREDENTIALS';

export const NEW = 'new';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const PUT = 'PUT';
export const POST = 'POST';
export const GET = 'GET';
export const REDIRECT = 'REDIRECT';

// URL about mimiro
export const ABOUT_LINK = 'https://www.eana.no/eana-skifte/';
export const PRIVACY_LINK = 'https://www.eana.no/info/personvernerklaering';

// Task Constants
export const COMPLETED = 'COMPLETED';
export const ASSIGNED = 'ASSIGNED';
export const ONGOING = 'ONGOING';
export const PAUSED = 'PAUSED';
export const OPEN = 'OPEN';
export const PLANNED = 'PLANNED';
export const DEFINED = 'DEFINED';
export const UNDEFINED = 'UNDEFINED';
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const DELETED = 'DELETED';

// languages
export const ENGLISH = 'English';
export const BOKMAL = 'Bokmål';
export const NYNORSK = 'Nynorsk';

// User Id
export const ADMIN = 'role1';
export const OWNER = 'role2';
export const EMPLOYEE = 'role3';
export const SUPPLIER = 'role4';
export const VIEWER = 'role5';
export const ADVISOR = 'role6';

// activities
export const ACT1 = 'act1';
export const ACT2 = 'act2';
export const ACT4 = 'act4';
export const ACT5 = 'act5';
export const ACT7 = 'act7';
export const ACT8 = 'act8';
export const ACT9 = 'act9';
export const ACT10 = 'act10';
export const ACT11 = 'act11';
export const ACT12 = 'act12';
export const ACT13 = 'act13';
export const ACT14 = 'act14';
export const ACT15 = 'act15';
export const ACT16 = 'act16';
export const ACT17 = 'act17';
export const ACT18 = 'act18';
export const ACT19 = 'act19';
export const ACT20 = 'act20';
export const ACT21 = 'act21';
export const ACT22 = 'act22';
export const ACT23 = 'act23';
export const ACT24 = 'act24';
export const ACT25 = 'act25';
export const ACT26 = 'act26';
export const ACT27 = 'act27';
export const ACT28 = 'act28';
export const ACT29 = 'act29';
export const ACT30 = 'act30';
export const ACT31 = 'act31';
export const ACT32 = 'act32';
export const NOTAPPLICABLE = 'Empty Activity';

//Fertlization constants
export const PESTICIDE_MINERAL_FERTILIZER_EN = 'Mineral fertilizer';
export const PESTICIDE_MINERAL_FERTILIZER_NB = 'Kunstgjødsel';
export const PESTICIDE_FOILAR_FERTILIZER_EN = 'Foliar fertilizer';
export const PESTICIDE_FOILAR_FERTILIZER_NB = 'Bladgjødsel';
export const PESTICIDE_LIMING_EN = 'Liming';
export const PESTICIDE_LIMING_NB = 'Kalk';
export const PESTICIDE_MANURE_EN = 'Manure';
export const PESTICIDE_MANURE_NB = 'Husdyrgjødsel';

export const MANURE_COW_EN = "Cow";
export const MANURE_DIARY_COW_EN = "Dairy cow w/ youngling";
export const MANURE_SUCKLING_COW_EN = "Suckling cow";
export const MANURE_OTHER_EN = "Other";
export const MANURE_PIG_EN = "Pig";
export const MANURE_POULTRY_EN = "Poultry";
export const MANURE_SHEEP_EN = "Sheep";
export const MANURE_COW_NN = "Ku";
export const MANURE_DIARY_COW_NN = "Mjølkeku m/ungdyr";
export const MANURE_SUCKLING_COW_NN = "Ammeku";
export const MANURE_OTHER_NN = "Anna";
export const MANURE_PIG_NN = "Svin";
export const MANURE_POULTRY_NN = "Fjørkre";
export const MANURE_SHEEP_NN = "Sau/geit";
export const MANURE_COW_NB = "Ku";
export const MANURE_DIARY_COW_NB = "Melkeku m/ungdyr";
export const MANURE_SUCKLING_COW_NB = "Ammeku";
export const MANURE_OTHER_NB = "Annet";
export const MANURE_PIG_NB = "Svin";
export const MANURE_POULTRY_NB = "Fjærkre";
export const MANURE_SHEEP_NB = "Sau/geit";


// Assets
export const TYPE4 = { id: 'type4', name: 'sprayer' }; // sprayer
export const TYPE1 = { id: 'type1', name: 'tractor' }; // Tractor
export const APPROVEDFROM = 'approvedfrom';
export const APPROVEDTO = 'approvedto';
export const VEHICLE_CATEGORY = 'cat1';
export const EQUIPMENT_CATEGORY = 'cat2';
// dynamic section constants
export const MANURE = 'Manure';
export const MINERALFERTILIZER = 'Mineral fertilizer';
export const FOILERFERTILIZER = 'Foliar fertilizer';
export const LIMING = 'Liming';
export const KORN = 'Korn';
export const OLJEVEKSTER = 'Oljevekster';
export const ERTER = 'Erter';
export const POTET = 'Potet';
export const JORDBR = 'Jordbær';
export const EPLER = 'Epler';
export const IKKEANGITT = 'Ikke angitt';

export const SPIRING = 'Spiring';
export const BLADUTVIKLING_HOVEDSKUDD = 'Bladutvikling (hovedskudd)';
export const BUSKING_UTVIKLING_AV_SIDESKUDD = 'Busking,  utvikling av sideskudd';
export const STREKKING_HOVEDSKUDD = 'Strekking (hovedskudd)';
export const AKS_ELLER_TOPPDANNELSE = 'Aks- eller toppdannelse';
export const SKYTING_HOVEDSKUDD = 'Skyting (hovedskudd)';
export const BLOMSTRING_HOVEDSKUDD = 'Blomstring (hovedskudd)';
export const FROKJERNE_UTVIKLING = 'Frø/kjerne utvikling';
export const MODNING = 'Modning';
export const VISNING = 'Visning';

export const UGRAS = 'Ugras';
export const POTETSYKDOMMER = 'Potetsykdommer';
export const SOPP = 'Sopp';
export const SKADEDYR = 'Skadedyr';
export const MANGELSYKDOMMER = 'Mangelsykdommer';
export const VEKSTAVSLUTNING = 'Vekstavslutning';
export const VEKSTREGULERING = 'Vekstregulering';
export const BEISING = 'Beising';

// roles check constant
export const ADD_EQUIPMENT = 'addEquipment';
export const CREATE_TASKS = 'createTasks';
export const CREATE_FARM = 'createFarm';
export const DELETE_EQUIPMENT = 'deleteEquipment';
export const DELETE_FARM = 'deleteFarm';
export const RECORD_ACTIVITY = 'recordActivity';
export const DELETE_USER = 'deleteUsers';
export const ADD_VEHICLE = 'addVehicle';
export const INVITE_USER = 'inviteUsers';
export const DELETE_VEHICLE = 'deleteVehicle';
export const UPDATE_ROLE = 'updateUserRole';
export const CREATE_FIELD = 'createField';
export const DELETE_FIELD = 'deleteField';
export const UPDATE_TASK = 'delegateTask';
export const DELETE_TASK = 'deleteTasks';

export const FILTER_TASK_FIELD = 'fieldFilterTask';
export const FILTER_TASK_STATUS = 'statusFilterTask';
export const FILTER_TASK_ACTIVITY = 'activityFilterTask';

export const TASK_TASKLIST = 'tasklist';
export const TASK_BATCH = 'batch';