import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { IMG_DOWNLOAD_REQUEST, LOCAL_KEY_USER } from 'constants/index';
import { GetLocalData, GetSessionData } from 'utils';
import NetworkMessage from 'containers/networkstatus/NetworkStatus';
import navToggle from 'actions/NavToggle';
import requestProfileImg from 'actions/ProfilePicture';
import { loginSuccess } from 'actions/Login';
import { taskListPosition } from 'actions/Tasks';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

class Header extends Component {
  componentDidMount = () => {
    const {
      AWSLoginDetails,
      userDetails,
      fetchProfileImg,
      profileImage,
      loginSucceeded,
      match,
      taskListPositionInfo
    } = this.props;
    if (!profileImage.data && !profileImage.hasError && AWSLoginDetails && AWSLoginDetails) {
      fetchProfileImg(AWSLoginDetails, IMG_DOWNLOAD_REQUEST, userDetails.id);
    }
    // Get data from local or session
    const user = GetLocalData(LOCAL_KEY_USER) || GetSessionData(LOCAL_KEY_USER);
    if (
      user &&
      user.preferredLanguage &&
      user.preferredLanguage !== userDetails.preferredLanguage
    ) {
      this.updateLang = setTimeout(() => {
        loginSucceeded(user);
      }, 1);
    }
    if (match.path === '/tasks/tasklist/:id' || match.url === '/tasks/tasklist/create') {
      taskListPositionInfo({ fromTask: match.url });
    } else {
      taskListPositionInfo({ fromTask: '' });
    }
  };

  componentDidUpdate = prevProps => {
    const { AWSLoginDetails, userDetails, fetchProfileImg, profileImage } = this.props;
    if (prevProps.AWSLoginDetails !== AWSLoginDetails && !profileImage.data) {
      fetchProfileImg(AWSLoginDetails, IMG_DOWNLOAD_REQUEST, userDetails.id);
    }
  };

  componentWillUnmount() {
    clearInterval(this.updateLang);
  }

  // handling the menu toggle.
  handleMenuToggle = () => {
    const { toggleNav, navBarToggle } = this.props;
    toggleNav(!navBarToggle.isOpen);
  };

  render() {
    const { bcLink, soilLink, bcTitle, positionCss, bcLinkArrow=false } = this.props;
    return (
      <>
        <header className={bcLinkArrow ? `header-new ${positionCss}` : `header ${positionCss}`}>
          <div className="breadcrumb-new">
            <p>
              {bcLink ? <Link style={{paddingBottom: 0}} to={bcLink.to}>{bcLink.text} </Link> : null}
              {bcLinkArrow && soilLink && <span style={{paddingBottom: 0, paddingRight: '20px' }}><ChevronRightIcon/></span>}
              {soilLink ? <Link style={{paddingBottom: 0}} to={soilLink.to}>{soilLink.text} </Link> : null}
              {bcLinkArrow && <span style={{paddingBottom: 0}}><ChevronRightIcon/></span>}
              {bcTitle ?
              <span title={bcTitle} style={{paddingBottom: 0}} className="header-title-name ellipsis-text">
                {bcTitle}
              </span> : null}
            </p>
          </div>
        </header>
        <NetworkMessage />
      </>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails.data,
  profileImage: state.profileImage,
  navBarToggle: state.NavToggle,
  hasValidationPermision: state.GpsData
});

const mapDispatchToProps = dispatch => ({
  fetchProfileImg: (awsLogin, type, userId) => dispatch(requestProfileImg(awsLogin, type, userId)),
  toggleNav: bool => dispatch(navToggle(bool)),
  loginSucceeded: data => dispatch(loginSuccess(data)),
  taskListPositionInfo: option => dispatch(taskListPosition(option))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
