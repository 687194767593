export const appLayoutBox = {
  display: 'flex',
  width: '100%',
  alignItems: 'flex-end',
  background: 'var(--M3-sys-light-surface-container-low, #F2F4F4)',
  height: '100%'
};
export const navigationBarLayout = {
  display: 'flex',
  width: '319px',
  padding: '20px 12px 6px 12px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '32px',
  alignSelf: 'stretch'
};
export const appLayoutBoxContainer = {
  display: 'flex',
  padding: '40px 0px 16px 18px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  flex: '1 0 0',
  alignSelf: 'stretch',
  background: 'var(--M3-sys-light-surface-container-low, #F2F4F4)'
};
export const appLayoutTypography = {
  display: 'flex',
  padding: '0px 16px 4px 16px',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'stretch'
};
export const appLayoutTitle = {
  color: 'var(--M3-sys-light-on-surface-variant, #3F4949)',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  paddingLeft: '10px'
};
export const mobileHeading = {
  color: 'var(--M3-sys-light-on-surface-variant, #3F4949)',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  textDecoration: 'none'
};
export const mobileHeadingBold = {
  ...mobileHeading,
  fontWeight: '600'
};
export const iconBackground = {
  height: '34px',
  width: '64px',
  paddingTop: '5px',
  backgroundColor: '#CCE8E9',
  borderRadius: '15px'
};
export const iconPlainBackground = {
  height: '34px',
  width: '64px',
  paddingTop: '5px',
  backgroundColor: '#FFFFFF',
  borderRadius: '15px'
};
export const menuStyle = {
  color: "var(--M3-sys-light-on-secondary-container, #3F4949)",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "20px",
  letterSpacing: "0.1px",
  paddingTop: "10px",
  paddingBottom: "10px"
};
export const menuStyleDark = {
  color: "var(--M3-sys-light-on-secondary-container, #041F21)",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "20px",
  letterSpacing: "0.1px",
  paddingTop: "10px",
  paddingBottom: "10px"
};
export const menuListItem = {
  padding: "10px 20px", 
  display: "flex", 
  alignItems: "center"
};
export const menuListItemHighlight = {
  padding: "10px 20px",
  display: "flex", 
  alignItems: "center",
  backgroundColor: "#CCE8E9",
  borderRadius: '100px'
};
export const listItemHighlight = {
  backgroundColor: "#CCE8E9",
  borderRadius: '100px'
};
export const settingsHeader = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flex: '1 0 0',
  alignSelf: 'stretch',
  background: '#F2F4F4',
  paddingTop: '14px',
  paddingBottom: '22px',
  paddingRight: '10px'
}
export const settingsHeaderLinkWhite = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  display: 'flex',
  background: '#FFFFFF'
}
export const settingsHeaderWhite = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flex: '1 0 0',
  alignSelf: 'stretch',
  background: '#FFFFFF',
  paddingTop: '14px',
  paddingBottom: '22px',
  paddingRight: '10px'
}
export const settingsHeaderLink = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  display: 'flex',
  background: '#F2F4F4'
}
export const settingsHeaderTitle = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  paddingRight: '8px',
  paddingTop: '2px'
}