import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import { AddIcon, TrashcanIcon, AlertIcon } from '@tine/lib-frontend-components/elements/icons';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import IconButton from '@tine/lib-frontend-components/components/buttons/icon-button';
import TaskFilter from 'containers/tasks/tasklist/TaskFilter';
import { reduxForm } from 'redux-form';
import { SetSessionData, GetSessionData } from 'utils';
import { isMobile } from 'react-device-detect';

import Selectbox from 'components/forms/Selectbox';
import ProfileImage from 'components/ProfileImage';
import TaskListItem from 'containers/tasks/tasklist/TaskListItem';
import {
  fetchTaskList,
  createUpdateTask,
  taskListSortByOption,
  deleteTasks,
  deleteInfoClear,
  selectedTaskCompleted
} from 'actions/Tasks';

import exportReport from 'actions/Reports';
import Loader from 'components/Loader';
import {
  TaskSortingOptions,
  ActivityTranslation,
  TaskPeriodOptions
} from 'constants/DropdownAndTranslationOptions';
import {
  UNDEFINED,
  COMPLETED,
  CREATE_TASKS,
  UPDATE_TASK,
  DELETE_TASK,
  FILTER_TASK_FIELD,
  FILTER_TASK_STATUS,
  FILTER_TASK_ACTIVITY
} from 'constants/index';
import { getUsers } from 'actions/Users';
import { checkPermissions } from 'utils/index';
import { TASK_PERIOD_VALUE } from 'constants/store/Tasks';
import { Dialog, DialogTitle, Stack, Box, Chip } from '@mui/material';
import { PlusIcon } from 'components/mui-forms/MuiIcons';
import { ADD_FIELD, ADD_TASK } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';
import { buttonRoundStyle, buttonStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';

const dialogTitleDialogStyle = { color: '#191C1C', fontFamily: 'Inter', fontSize: '24px', fontStyle: 'normal', fontWeight: '400', lineHeight: '32px' }
const dialogSubTitleStyle = { color: '#3F4949', fontFamily: 'Inter', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight: '20px', letterSpacing: '0.25px', padding: '10px 24px' }

class ManageTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedItems: new Map(),
      assignee: '',
      assigeUpdateConfirm: false,
      deleteConfirmShow: false,
      selectedAssigneeData: {},
      hasCompletedTask: false,
      assignOnlyCompletedTask: false,
      clearSelection: false,
      hasPermission: {
        createTask: false,
        deletetask: false,
        updateTask: false
      },
      displayFilter: false,
      displayMultiple: true,
      taskListFields: [],
      taskListStatuses: [],
      taskListActivities: [],
      filterChange: false,
      fetchTaskDetails: true,
      resetFilter: false,
      completedTaskState: false,
      deleteTaskState: false,
      show: false
    };
    this.handleFilterFieldChange = this.handleFilterFieldChange.bind(this);
    this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
    this.handleFilterTaskChange = this.handleFilterTaskChange.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  componentDidMount = () => {
    const {
      activeFarm,
      userDetails,
      fetchAssignee,
      assignees,
      farmsData,
      history,
      taskList
    } = this.props;
    if (activeFarm) {
      const fieldValue = this.filterValueList("field");
      const statusValue = this.filterValueList("status");
      const taskValue = this.filterValueList("task");      
      if((fieldValue !== null && fieldValue.length > 0) || (statusValue !== null && statusValue.length > 0) || 
          (taskValue !== null && taskValue.length > 0)) {
          this.setState({ filterChange: true, displayFilter: true });
      } else {
        this.setState({ filterChange: false, displayFilter: false });
      }
      let taskCount;
      if (taskList.taskListPositionInfo.fromTask && taskList.data) {
        taskCount = taskList.data.length;
        this.fetchList(0, taskCount < 15 ? 15 : taskCount, null, taskList.taskPeriod, 
          statusValue ? statusValue : null, taskValue ? taskValue : null, fieldValue ? fieldValue : null);
      } else {
        this.fetchList(0, 15, null, taskList.taskPeriod, statusValue ? statusValue : null, 
          taskValue ? taskValue : null, fieldValue ? fieldValue : null);
      }
      if (!assignees.users) {
        fetchAssignee(activeFarm.farm.id, userDetails.idToken);
      }
      this.updatePermission();
    }
    if (farmsData && !farmsData.isLoading && farmsData.data && farmsData.data.length < 1) {
      history.push('/managefields');
    }
  };

  componentDidUpdate(prevProps) {
    const { activeFarm, userDetails, fetchAssignee, taskList } = this.props;
    const { filterChange, fetchTaskDetails, resetFilter, completedTaskState, deleteTaskState } = this.state;
    if (prevProps.activeFarm !== activeFarm) {
      window.location.replace("/#/tasks");
      window.location.reload();
    }
    if(fetchTaskDetails && taskList.data && !taskList.isLoading) {
      this.setState({taskListFields: taskList.paginationDetails.fields});
      this.setState({taskListStatuses: taskList.paginationDetails.statuses});
      this.setState({taskListActivities: taskList.paginationDetails.activities});      
      this.setState({fetchTaskDetails: false});
      SetSessionData(FILTER_TASK_STATUS, this.reAssignValueList('status',taskList.paginationDetails.statuses));
      SetSessionData(FILTER_TASK_ACTIVITY, this.reAssignValueList('task',taskList.paginationDetails.activities));
    }
    const fieldValue = resetFilter ? [] : this.filterValueList("field");
    const statusValue = resetFilter ? [] : this.filterValueList("status");
    const taskValue = resetFilter ? [] : this.filterValueList("task");
    if (activeFarm !== prevProps.activeFarm || (!completedTaskState && taskList.isCompletedSuccess) || (!deleteTaskState && taskList.deleteSuccess)) {
      this.fetchList(0, 15, null, taskList.taskPeriod, statusValue ? statusValue : null, 
        taskValue ? taskValue : null, fieldValue ? fieldValue : null);
      fetchAssignee(activeFarm.farm.id, userDetails.idToken);
      this.clearSelection(true);
      this.setState({
        completedTaskState: true,
        deleteTaskState: true
      });
      this.updatePermission();
    }
    if (filterChange){
      this.fetchList(0, 15, taskList.sortBy?.value, taskList.taskPeriod, statusValue ? statusValue : null, 
        taskValue ? taskValue : null, fieldValue ? fieldValue : null);
      this.setState({resetFilter: false, filterChange: false});
    }
    if (taskList !== prevProps.taskList) {
      this.clearSelection();
    }
  }

  /**
   * to reassign the session data
   * @param {*} fieldFilterValue 
   * @returns 
  */
  reAssignValueList = (fieldFilterValue, taskList) => {
    var fieldValue = [];    
    switch (fieldFilterValue) {
      case 'status':
        if(GetSessionData(FILTER_TASK_STATUS) && GetSessionData(FILTER_TASK_STATUS) !== null) {        
          if(GetSessionData(FILTER_TASK_STATUS).length > 0) {
            for (let eventValue of GetSessionData(FILTER_TASK_STATUS)) {
              let keyPair = {};
              keyPair['value'] = eventValue.value;
              keyPair['label'] = taskList.filter(opt => opt.id === eventValue.value)[0] && taskList.filter(opt => opt.id === eventValue.value)[0]['name'];
              fieldValue.push(keyPair);
            }            
          }
        }
        return fieldValue;
      case 'task':
        if(GetSessionData(FILTER_TASK_ACTIVITY) && GetSessionData(FILTER_TASK_ACTIVITY) !== null) {        
          if(GetSessionData(FILTER_TASK_ACTIVITY).length > 0) {
            for (let eventValue of GetSessionData(FILTER_TASK_ACTIVITY)) {
              let keyPair = {};
              keyPair['value'] = eventValue.value;
              keyPair['label'] = taskList.filter(opt => opt.id === eventValue.value)[0] && taskList.filter(opt => opt.id === eventValue.value)[0]['activityName'];
              fieldValue.push(keyPair);
            }           
          }
        }
        return fieldValue;
      default:
        return fieldValue;
    }
  }

  /**
   * to get the filter values
   * @param {*} fieldFilterValue 
   * @returns 
   */
  filterValueList = (fieldFilterValue) => {
    var fieldValue = [];
    switch (fieldFilterValue) {
      case 'field':
        if(GetSessionData(FILTER_TASK_FIELD) && GetSessionData(FILTER_TASK_FIELD) !== null) {        
          if(GetSessionData(FILTER_TASK_FIELD).length > 0) {
            for (let eventValue of GetSessionData(FILTER_TASK_FIELD)) {
              fieldValue.push(eventValue.value);
            }
          }
        }
        return fieldValue;
      case 'status':
        if(GetSessionData(FILTER_TASK_STATUS) && GetSessionData(FILTER_TASK_STATUS) !== null) {        
          if(GetSessionData(FILTER_TASK_STATUS).length > 0) {
            for (let eventValue of GetSessionData(FILTER_TASK_STATUS)) {
              fieldValue.push(eventValue.value);
            }
          }
        }
        return fieldValue;
      case 'task':
        if(GetSessionData(FILTER_TASK_ACTIVITY) && GetSessionData(FILTER_TASK_ACTIVITY) !== null) {        
          if(GetSessionData(FILTER_TASK_ACTIVITY).length > 0) {
            for (let eventValue of GetSessionData(FILTER_TASK_ACTIVITY)) {
              fieldValue.push(eventValue.value);
            }
          }
        }
        return fieldValue;
      default:
        return fieldValue;
    }
  }

  /**
   * to get the task list
   * @param { int, int, string }
   */
  fetchList = (offset, listSize, sortBy, days, statuses, activityIds, fieldIds) => {
    const { activeFarm, getTaskList, taskList } = this.props;
    const sortByValue = sortBy || taskList.sortBy.value;
    getTaskList(
      activeFarm.farm.id,
      sortByValue === 'Date created' ? '' : sortByValue.toLowerCase(),
      offset,
      listSize,
      days,
      statuses,
      activityIds,
      fieldIds
    );
  };

  /**
   * Get sort option list
   * @returns {array}
   */
  getSortByOptions = () => {
    const { intl } = this.props;
    return TaskSortingOptions.map(option => ({
      value: option.value,
      label: intl.formatMessage({ id: option.label })
    }));
  };

  /**
   * To clear the selected task items
   * @param { boolean } optional
   *  */

  clearSelection = clear => {
    const { clearSelection } = this.state;
    if (clearSelection || clear) {
      this.setState({
        checkedItems: new Map(),
        assignee: '',
        selectedAssigneeData: {},
        clearSelection: false,
        hasCompletedTask: false
      });
    }
  };

  selectSelection = () => {
    const { taskList } = this.props;
    if(taskList && taskList.data){
      //eslint-disable-next-line
      taskList.data.map(taskItem => {
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(taskItem.id, true) }));
      });
    }
  }

  /**
   * update permission check
   */
  updatePermission = () => {
    const { activeFarm, rolesDetails } = this.props;
    this.setState({
      hasPermission: {
        deletetask: checkPermissions(DELETE_TASK, activeFarm, rolesDetails),
        updateTask: checkPermissions(UPDATE_TASK, activeFarm, rolesDetails),
        createTask: checkPermissions(CREATE_TASKS, activeFarm, rolesDetails)
      }
    });
  };

  // To get the selected task items from the list
  getSelectedTask = values => new Map([...values].filter(([k, v]) => v === true));

  // Check or un-check the task item
  handleCheckbox = e => {
    const item = e.target.id;
    const isChecked = e.target.checked;
    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
  };

  /**
   * List the assigne in the dropdown
   * @returns {array} dropdown options
   */
  assigneeOptions = () => {
    const { assignees, profilePictures } = this.props;
    if (assignees.users) {
      return assignees.users.map(data => {
        const userImage = _.find(profilePictures, ['userId', data.user.id]);
        return {
          value: data.user.id,
          label: [
            <span className="user-name">{data.user.firstName}</span>,
            <ProfileImage size="dropdown-image" imgUrl={userImage ? userImage.image : null} />
          ]
        };
      });
    }
    return [];
  };

  /**
   * To get selected task object
   * @returns { Object }
   */
  getSelectedTaskDetails = () => {
    const { taskList } = this.props;
    const { checkedItems } = this.state;
    const selectedItem = this.getSelectedTask(checkedItems);
    const selectedtaskObj = [];
    selectedItem.forEach((v, k) => selectedtaskObj.push(_.filter(taskList.data, ['id', k])[0]));
    return selectedtaskObj;
  };

  /**
   * to delete tasks
   * @param {boolean} action
   */
  deleteTaskItems = action => {
    const { deleteSelectedTasks, activeFarm } = this.props;
    const { checkedItems } = this.state;
    if (action) {
      const selectedItem = this.getSelectedTask(checkedItems);
      const tasks = [];
      selectedItem.forEach((v, k) => tasks.push(k));
      deleteSelectedTasks(tasks, activeFarm.farm.id);
    }
    this.clearSelection(true);
    this.setState(prevState => ({
      deleteConfirmShow: !prevState.deleteConfirmShow,
      clearSelection: true,
      deleteTaskState: false
    }));
  };

  // On change of assignee dropdown
  onAssigneeChange = assignee => {
    const { assignees } = this.props;
    const selectedTasks = this.getSelectedTaskDetails();
    const completedtask = _.filter(selectedTasks, ['status', COMPLETED]);
    if (completedtask.length === selectedTasks.length) {
      this.setState(prevState => ({
        hasCompletedTask: true,
        assignOnlyCompletedTask: !prevState.assignOnlyCompletedTask
      }));
    } else {
      this.setState(prevState => ({
        assignee,
        assigeUpdateConfirm: !prevState.assigeUpdateConfirm,
        selectedAssigneeData: _.filter(assignees.users, ['user.id', assignee.value])[0].user,
        hasCompletedTask: completedtask.length > 0,
        clearSelection: true
      }));
    }
  };

  // On click of completed button
  completeSelectedTask = () => {
    const { selectedTaskCompleted, activeFarm } = this.props;
    const selectedTasks = this.getSelectedTaskDetails();
    const taskList = [];
    selectedTasks.map(task => (
      taskList.push(task.id)
    ));
    selectedTaskCompleted(activeFarm.farm.id, taskList);
    this.setState({
      completedTaskState: false
    });
  };

  /**
   * On change of sort by dropdown
   * @param {object}
   */
  onChangeSortby = sortBy => {
    const { sortyByOption, taskList } = this.props;
    sortyByOption(sortBy);
    const fieldValue = this.filterValueList("field");
    const statusValue = this.filterValueList("status");
    const taskValue = this.filterValueList("task");
    this.fetchList(0, 15, sortBy.value, taskList.taskPeriod, statusValue, taskValue, fieldValue);
  };

  /**
   * on change of choose period dropdown
   * @param {number} days
   */
  onChangePeriod = days => {
    const { taskList, periodOption } = this.props;
    periodOption(days?.value);
    const fieldValue = this.filterValueList("field");
    const statusValue = this.filterValueList("status");
    const taskValue = this.filterValueList("task");
    this.fetchList(0, 15, taskList.sortBy?.value, days?.value, statusValue, taskValue, fieldValue);
  };

  /**
   * To fetch more task
   * Void
   */
  fetchMoreTask = () => {
    const { taskList } = this.props;
    const { isLoading, isDeleteLoading, data, paginationDetails, taskPeriod } = taskList;
    const fieldValue = this.filterValueList("field");
    const statusValue = this.filterValueList("status");
    const taskValue = this.filterValueList("task");
    if (
      !(isLoading || isDeleteLoading) &&
      data &&
      data.length > 0 &&
      data.length < paginationDetails.totalTaskCount
    ) {
      this.setState({ clearSelection: false });
      this.fetchList(data.length, 15, null, taskPeriod, statusValue, taskValue, fieldValue);
    }
  };

  /**
   * filter based on field selection
   * @param {*} e 
   */
  handleFilterFieldChange = (e) => {
    SetSessionData(FILTER_TASK_FIELD, e);
    this.setState({ filterChange: true });
  }

  /**
   * filter based on status selection
   * @param {*} e 
   */
  handleFilterStatusChange = (e) => {
    SetSessionData(FILTER_TASK_STATUS, e);
    this.setState({ filterChange: true });
  }

  /**
   * filter based on task selection
   * @param {*} e 
   */
  handleFilterTaskChange = (e) => {
    SetSessionData(FILTER_TASK_ACTIVITY, e);
    this.setState({ filterChange: true });
  }

  /**
   * reset the filter
   * @param {*} e 
   */
  resetFilter() {
    const { periodOption, sortyByOption } = this.props;
    sessionStorage.removeItem(FILTER_TASK_FIELD);
    sessionStorage.removeItem(FILTER_TASK_STATUS);
    sessionStorage.removeItem(FILTER_TASK_ACTIVITY);
    periodOption(null);
    sortyByOption(
      {
        value: 'Date created',
        label: <FormattedMessage id="DATE_CREATED" />
      }
    );    
    this.setState({ resetFilter: true, filterChange: true });
  }

  /**
   * Update assignee on confirmation
   * @param {boolean} action
   */

  updateTaskAssignee = action => {
    const { updateAssignee } = this.props;
    const { selectedAssigneeData } = this.state;
    if (action) {
      let selectedTasks = this.getSelectedTaskDetails();
      _.remove(selectedTasks, task => task.status === COMPLETED);
      selectedTasks = selectedTasks.map(task => {
        const taskUpdateAssignee = task;
        taskUpdateAssignee.assignee = selectedAssigneeData;
        return taskUpdateAssignee;
      });
      updateAssignee(selectedTasks, 'assign');
    } else {
      this.setState({
        assignee: ''
      });
    }
    this.setState(prevState => ({ assigeUpdateConfirm: !prevState.assigeUpdateConfirm }));
  };

  /**
   * Assignee confimation boby content
   * @param {array} selectedtask
   * @returns { HTMLElement }
   */
  getAssigneeConfirmMsg = selectedCount => {
    const { selectedAssigneeData, hasCompletedTask } = this.state;
    const { intl } = this.props;
    return `<div class="confirm-msg-wrapper"> 
        <p>${intl.formatMessage({
          id: 'ASSIGN_TASK_TEXT_1'
        })} ${selectedCount} ${intl.formatMessage({ id: 'ASSIGN_TASK_TEXT_2' })} ${
      selectedAssigneeData.firstName
    } ${selectedAssigneeData.lastName}</p>
        ${hasCompletedTask ? `<p>${intl.formatMessage({ id: 'ASSIGN_TASK_TEXT_3' })}</p>` : ''}
      </div>`;
  };

  /**
   * Assignee confimation boby content
   * @param {array} selectedtask
   * @returns { HTMLElement }
   */
  getDeleteConfirmMsg = selectedCount => {
    const { intl } = this.props;
    return `<div class="confirm-msg-wrapper"> 
        <p>${selectedCount} ${intl.formatMessage({ id: 'DELETE_TASK_TEXT_1' })} </p>
      </div>`;
  };

  clearDeleteFlag = () => {
    const { clearDeleteSucess } = this.props;
    clearDeleteSucess(false);
  };

  /**
   * Grouping the tasks items
   * @returns {object}
   */
  getSortByValue = () => {
    const { taskList } = this.props;
    switch (taskList.sortBy.value) {
      case 'Date created':
        return {
          type: 'createdTimestamp',
          data: taskList.data
        };
      case 'field':
        return { type: 'field', data: _.groupBy(taskList.data, 'fieldName') };
      case 'activity':
        return { type: 'activity', data: _.groupBy(taskList.data, 'activityName') };
      case 'user':
        return { type: 'assignee', data: _.groupBy(taskList.data, 'assigneeFirstName') };
      case 'status':
        return {
          type: 'status',
          data: _.groupBy(taskList.data, value => value.status.toUpperCase())
        };
      default:
        return {
          type: 'createdTimestamp',
          data: taskList.data
        };
    }
  };

  /**
   *  to get the title of sortby value
   * @param {string} value
   * @returns {string}
   */
  getSortbyName = value => {
    const { taskList } = this.props;
    switch (taskList.sortBy.value) {
      case 'activity':
        if (value.toUpperCase() === UNDEFINED) {
          return <FormattedMessage id="NOT_DEFINED" />;
        }
        return <FormattedMessage id={ActivityTranslation(value)} />;
      case 'user':
        if (value.toUpperCase() === UNDEFINED) {
          return <FormattedMessage id="PLANNED" />;
        }
        return value.toLowerCase();
      case 'field':
        if (value.toUpperCase() === UNDEFINED) {
          return <FormattedMessage id="NOT_DEFINED" />;
        }
        return value.toLowerCase();
      default:
        return value.toLowerCase();
    }
  };

  /**
   * to get task cost in the group
   */
  getTaskCount = (groupBy, groupObject) => {
    const { taskList } = this.props;
    switch (groupBy) {
      case 'field':
        return groupObject.field
          ? taskList.paginationDetails.sortFieldMap[groupObject.field.fieldId]
          : taskList.paginationDetails.sortFieldMap[UNDEFINED.toLowerCase()];
      case 'activity':
        return groupObject.activity
          ? taskList.paginationDetails.sortFieldMap[groupObject.activity.id]
          : taskList.paginationDetails.sortFieldMap[UNDEFINED.toLowerCase()];
      case 'assignee':
        return groupObject.assignee
          ? taskList.paginationDetails.sortFieldMap[groupObject.assignee.id]
          : null;
      case 'status':
        return taskList.paginationDetails.sortFieldMap[groupObject.status];
      default:
        return null;
    }
  };

  displayingFilter = () => {
    this.setState(prevState => ({
      displayFilter: !prevState.displayFilter,
      fetchTaskDetails: true
    }));
  };

  /**
   * To render the task item list
   * @returns {array} task list component
   */
  renderList = () => {
    const { taskList, intl } = this.props;
    const { checkedItems, hasPermission } = this.state;
    const { isLoading, isDeleteLoading } = taskList;
    if (taskList.data && taskList.data.length > 0) {
      const sortedData = this.getSortByValue();
      if (taskList.sortBy.value === 'Date created') {
        return (
          <ul className="task-list-wrapper full-width">
            {sortedData.data.map(item => (
              <TaskListItem
                TaskDetails={item}
                intl={intl}
                handleCheckbox={this.handleCheckbox}
                checked={!!checkedItems.get(item.id)}
                key={item.id}
                isLoading={isLoading || isDeleteLoading}
                hasPermission={hasPermission}
              />
            ))}
          </ul>
        );
      }
      return (
        <div className="full-width">
          {Object.keys(sortedData.data).map(key => (
            <div key={key} className="full-width">
              <div className="full-width task-list-divider">
                <div className="full-width task-list-divider-elm">
                  <span className="activity-count">
                    {this.getTaskCount(sortedData.type, sortedData.data[key][0])}{' '}
                    <FormattedMessage
                      id={sortedData.data[key].length > 1 ? 'ACTIVITIES' : 'ACTIVITY'}
                    />
                  </span>
                  <span className="task-list-name">{this.getSortbyName(key)}</span>
                  <div className="divider-line" />
                </div>
              </div>
              <ul className="task-list-wrapper full-width">
                {sortedData.data[key].map(item => (
                  <TaskListItem
                    TaskDetails={item}
                    handleCheckbox={this.handleCheckbox}
                    checked={!!checkedItems.get(item.id)}
                    key={item.id}
                    isLoading={isLoading || isDeleteLoading}
                    hasPermission={hasPermission}
                  />
                ))}
              </ul>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  createTaskButton = () => {
    const { history, userDetails } = this.props;
    const { taskListFields } = this.state;
    mixpanel.identify(userDetails.id);
    mixpanel.track(ADD_TASK);
    if(taskListFields.length > 0) {
      this.setState({
        show: false
      });
      history.push("/tasks/tasklist/create");
    } else {
      this.setState({
        show: true
      });
    }
  }

  cancelDialog = () => {
    this.setState({
      show: false
    });
  }

  redirectDialog = () => {
    const { history, userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(ADD_FIELD);
    this.setState({
      show: false
    });
    history.push("/managefields/new");
  }

  render() {
    const { taskList, intl, exportTasks, activeFarm, reportDetails, userDetails } = this.props;
    const { isReportLoading } = reportDetails;
    const {
      checkedItems,
      assigeUpdateConfirm,
      deleteConfirmShow,
      assignOnlyCompletedTask,
      hasPermission,
      displayFilter,
      displayMultiple,
      taskListFields,
      taskListStatuses,
      taskListActivities,
      resetFilter,
      show
    } = this.state;
    const {
      isLoading,
      isDeleteLoading,
      data,
      paginationDetails,
      deleteSuccess,
      taskPeriod
    } = taskList;
    const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', width: '340px' };
    const titleFont = {
      color: '#191C1C',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '32px'
    }
    const subTitleFont = {
      color: '#3F4949',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px'
    }
    const selectedCount = this.getSelectedTask(checkedItems).size;
    return (
      <div className={`full-width ${isMobile ? 'main-content-mobile' : 'main-content'}`} style={{ backgroundColor: "#ffffff" }}>
        <div style={{ padding: '0px 20px 0px 40px' }}>
          {(hasPermission.createTask && paginationDetails && paginationDetails.listTaskCount > 0) ? (
            <>
            <div
                className={`link-add btn-add-task ${!hasPermission.createTask ? 'disable' : ''}`}
              >
                <Link to="/tasks/tasklist/create" onClick={() => {mixpanel.identify(userDetails.id); mixpanel.track(ADD_TASK);}}>
                  <Text>
                    <FormattedMessage id="TASKS_ADD" />
                  </Text>
                  <AddIcon scale={0.75} />
                </Link>
              </div>
              {!isReportLoading && <button
                type="button"
                onClick={() => {
                  exportTasks(activeFarm, taskPeriod, this.filterValueList('status'), this.filterValueList('task'), this.filterValueList('field'), taskList.sortBy.value);
                }}
              className={`btn-icon-txt export-task ${!hasPermission.createTask ? 'disable' : ''}`}
              >
                <span>
                  <FormattedMessage id="TASK_EXPORT" />
                </span>
                <i className="icon-report-download" />
              </button>}
              {isReportLoading && <button
                type="button"
                className={`btn-icon-txt no-cursor export-task`}
              >
                <span>
                  <FormattedMessage id="PLEASE_WAIT" />
                </span>
              </button>}{' '}
            </>
          ) : null}
          {paginationDetails && paginationDetails.listTaskCount > 0 &&
          <button
            type="button"
            onClick={() => {
              this.displayingFilter();
            }}
            className={`btn-icon-txt task-filter ${displayFilter ? 'task-filter-selected' : ''}`}
          >
            <span>
              <FormattedMessage id="FILTER" />
            </span>
            <img src="images/filter1.png" width="25" height="17" alt="Filter" />
          </button>}
          {paginationDetails && paginationDetails.listTaskCount > 0 &&
          <div className="tm-filter">
            <span className="total-task">
              <Emphasis>
                {paginationDetails && paginationDetails.totalTaskCount
                  ? `${paginationDetails.totalTaskCount} ${intl.formatMessage({
                      id: 'TASK_S'
                    })}`
                  : `0 ${intl.formatMessage({ id: 'TASK_S' })}`}
              </Emphasis>
            </span>
            <div className="tm-filter-sortby sort-by">
              <span className="filter-dd-lbl">
                <Text>
                  <FormattedMessage id="SORT_BY" />:
                </Text>
              </span>
              <Selectbox
                styleName="select-box-common filter-dropdown"
                options={this.getSortByOptions()}
                disabled={isLoading || isDeleteLoading}
                value={taskList.sortBy}
                onChange={v => this.onChangeSortby(v)}
              />
            </div>
            <div className="tm-filter-sortby">
              <span className="filter-dd-lbl">
                <Text>{intl.formatMessage({ id: 'CHOOSE_PERIOD' })}</Text>:
              </span>
              <Selectbox
                styleName="select-box-common filter-dropdown"
                options={TaskPeriodOptions}
                disabled={isLoading || isDeleteLoading}
                onChange={v => this.onChangePeriod(v)}
                defaultValue={TaskPeriodOptions.filter(opt => opt.value === taskPeriod)}
                value={TaskPeriodOptions.filter(opt => opt.value === taskPeriod)}
              />
            </div>
          </div>}
          {paginationDetails && paginationDetails.listTaskCount === 0 &&
            <div className="manageFarmsLayout">
              <div className="manageFarmsLayoutDivLayout" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                <div className="manageFarmsLayoutDiv">
                  <div><img src="images/NoTasks.png" alt="No Tasks" width="105" /></div>
                  <Box style={inputStyle}>
                    <Stack className="text-with-padding">
                      <div style={titleFont}>
                        {intl.formatMessage({ id: 'TASKS' })}
                      </div>
                    </Stack>
                    <Stack className="text-with-padding">
                      <div style={subTitleFont}>
                        {intl.formatMessage({ id: 'NO_TASKS_CONTENT' })}
                      </div>
                    </Stack>
                    <div style={{ padding: '20px 0px', paddingRight: '0px !important' }}>
                      <Stack direction="row" justifyContent="center">
                        <Chip
                          icon={<PlusIcon />}
                          label={intl.formatMessage({ id: 'TASKS_ADD' })}
                          color="primary"
                          variant="outlined"
                          clickable
                          className={`${!hasPermission.createTask ? 'disable' : ''}`}
                          onClick={() => this.createTaskButton()}
                          style={buttonRoundStyle}
                        />
                      </Stack>
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          }
        </div>
        {displayFilter && paginationDetails && paginationDetails.listTaskCount > 0 ? 
          <TaskFilter
            displayMultiple={displayMultiple}
            handleFilterFieldChange={this.handleFilterFieldChange}
            handleFilterStatusChange={this.handleFilterStatusChange}
            handleFilterTaskChange={this.handleFilterTaskChange}
            intl={intl}
            taskListFields={taskListFields}
            taskListStatuses={taskListStatuses}
            taskListActivities={taskListActivities}
            resetFilter={this.resetFilter}
            defaultField={resetFilter ? [] : (GetSessionData(FILTER_TASK_FIELD) && GetSessionData(FILTER_TASK_FIELD).length > 0) ? GetSessionData(FILTER_TASK_FIELD) : []}
            defaultStatus={resetFilter ? [] : (GetSessionData(FILTER_TASK_STATUS) && GetSessionData(FILTER_TASK_STATUS).length > 0) ? GetSessionData(FILTER_TASK_STATUS) : []}
            defaultTask={resetFilter ? [] : (GetSessionData(FILTER_TASK_ACTIVITY) && GetSessionData(FILTER_TASK_ACTIVITY).length > 0) ? GetSessionData(FILTER_TASK_ACTIVITY) : []}
          /> : '' }
        <div className="task-list-container full-width">
          {isLoading || isDeleteLoading ? (
            <Loader styleName="center-align task-list-loader" />
          ) : null}
          {this.renderList()}
          {data && data.length < paginationDetails.totalTaskCount ? (
            <div className="full-width load-more-wrapper">
              <ActionButton
                secondary
                disabled={isLoading || isDeleteLoading}
                onClick={() => this.fetchMoreTask()}
              >
                {intl.formatMessage({ id: 'LOAD_MORE' })}
              </ActionButton>
            </div>
          ) : null}
        </div>
        {assigeUpdateConfirm && hasPermission.updateTask ? (
          <ConfirmDialogBox
            heading={intl.formatMessage({ id: 'ASSIGN' })}
            body={this.getAssigneeConfirmMsg(selectedCount)}
            confirmText="ASSIGN"
            cancelText="CANCEL"
            callback={e => this.updateTaskAssignee(e)}
          />
        ) : null}
        {deleteSuccess && this.clearDeleteFlag()}
        {deleteConfirmShow && hasPermission.deletetask ? (
          <ConfirmDialogBox
            heading={intl.formatMessage({ id: 'DELETE' })}
            body={this.getDeleteConfirmMsg(selectedCount)}
            confirmText="DELETE"
            cancelText="CANCEL"
            callback={e => this.deleteTaskItems(e)}
            warning
          />
        ) : null}
        <ModalDialog
          show={assignOnlyCompletedTask}
          width={{ mobile: '200px', tablet: '200px', web: '200px' }}
          height={{ mobile: '300px', tablet: '300px', web: '300px' }}
          closeIcon=""
          onClose={() => {}}
        >
          <div className="completed-assign-msg">
            <AlertIcon scale={1.5} />
            <p>{intl.formatMessage({ id: 'ASSIGN_TASK_TEXT_3' })}</p>
            <ActionButton
              onClick={() =>
                this.setState(prevState => ({
                  assignOnlyCompletedTask: !prevState.assignOnlyCompletedTask
                }))
              }
            >
              {intl.formatMessage({ id: 'BACK' })}
            </ActionButton>
          </div>
        </ModalDialog>
        {show && (
          <Dialog open={show} onClose={() => {}} PaperProps={{
            style: {
              backgroundColor: '#E6E9E8',
              borderRadius: "28px",
              width: "320px",
              minWidth: "200px",
              maxWidth: "320px",
            }}}>
            <DialogTitle style={dialogTitleDialogStyle}>
              {intl.formatMessage({ id: 'TASK_BUTTON_NO_FIELD_HEADING' })}
            </DialogTitle>
            <Box component="div" style={dialogSubTitleStyle}>
              {intl.formatMessage({ id: 'TASK_BUTTON_NO_FIELD_CONTENT' })}
            </Box>
            <div style={{padding: "15px", textAlign: "right"}}>
              <span>
                <Chip
                  label={intl.formatMessage({ id: 'CANCEL' })}
                  clickable
                  style={buttonStyle}
                  sx={{ backgroundColor: 'transparent',
                    '&:hover, &:focus': {
                      backgroundColor: 'transparent',
                    }
                  }}
                  disableRipple
                  onClick={() => this.cancelDialog()}
                />
                <Chip
                  label={intl.formatMessage({ id: 'FV_AF' })}
                  clickable
                  style={buttonStyle}
                  sx={{ backgroundColor: 'transparent',
                    '&:hover, &:focus': {
                      backgroundColor: 'transparent',
                    }
                  }}
                  disableRipple
                  onClick={() => this.redirectDialog()}
                />
              </span>
            </div>
          </Dialog>
        )}
        {selectedCount > 0 && (hasPermission.deletetask || hasPermission.updateTask) ? (
          <div className="task-list-update">
            <span className="selected-task">
              {intl.formatMessage({ id: 'YOU_SELECTED' })} <span>{selectedCount}</span>{' '}
              {intl.formatMessage({ id: 'TASK_S' })}
            </span>
            <ActionButton tertiary onClick={() => this.selectSelection()}>
              {intl.formatMessage({ id: 'ADD_ANALYSIS_CHECKBOX' })}
            </ActionButton>
            <ActionButton tertiary onClick={() => this.clearSelection(true)}>
              {intl.formatMessage({ id: 'REMOVE_SELECTION' })}
            </ActionButton>
            {/*<Selectbox
              styleName="select-box-common user-dropdown padding-right-user-dropdown"
              value={assignee}
              options={this.assigneeOptions()}
              onChange={v => this.onAssigneeChange(v)}
              menuPlacement="top"
              placeholder={[
                <span className="user-name">{intl.formatMessage({ id: 'ASSIGN_TASK' })}</span>,
                <ProfileImage />
              ]}
            />*/}
            <ActionButton tertiary onClick={() => this.completeSelectedTask()}>
              {intl.formatMessage({ id: 'COMPLETE_SELECTED_TASK' })}
            </ActionButton>
            <div className="delete-tasks">
              <span>{intl.formatMessage({ id: 'DELETE_SELECTED_TASK' })}</span>
              <IconButton
                onClick={() => {
                  this.setState(prevState => ({
                    deleteConfirmShow: !prevState.deleteConfirmShow
                  }));
                }}
              >
                <TrashcanIcon scale={0.85} />
              </IconButton>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails.data,
  /*AWSLoginDetails: state.AWSLogin.data,*/
  rolesDetails: state.RolesDetails,
  activeFarm: state.farmList.activeFarm,
  taskList: state.TaskList,
  assignees: state.farmUserDetails.userList,
  profilePictures: state.profileImage.userImage,
  farmsData: state.farmList,
  reportDetails: state.reportDetails,
  activitiesCommon: state.masterData.data[0]?.activities
});

const mapDispatchToProps = dispatch => ({
  getTaskList: (farmId, sortBy, offset, numberOfTasks, days, statuses, activityIds, fieldIds) =>
    dispatch(fetchTaskList(farmId, sortBy, offset, numberOfTasks, days, statuses, activityIds, fieldIds)),
  fetchAssignee: (farmId, token) => dispatch(getUsers(farmId, token)),
  updateAssignee: (tasks, type) => dispatch(createUpdateTask(tasks, type)),
  deleteSelectedTasks: (tasks,farmId) => dispatch(deleteTasks(tasks,farmId)),
  sortyByOption: option => dispatch(taskListSortByOption(option)),
  clearDeleteSucess: data => dispatch(deleteInfoClear(data)),
  exportTasks: (farm, days, statuses, activityIds, fieldIds, sortField) => dispatch(exportReport(farm, days, statuses, activityIds, fieldIds,sortField)),
  periodOption: days => dispatch({ type: TASK_PERIOD_VALUE, taskPeriod: days }),
  selectedTaskCompleted: (farmId, data) => dispatch(selectedTaskCompleted(farmId, data)),
});

export default injectIntl(
  reduxForm({
    form: 'ManageTask'
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageTask)))
);
