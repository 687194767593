import * as React from 'react';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import MetaInformation from '@tine/lib-frontend-components/elements/typography/text/meta-information';

export default ({ fieldFertilizationItem, intl }) => (
  <div className="fertilizer-plan-padding">
    <div className="manure-item_header">
      <EmphasisLight size="large">{fieldFertilizationItem.fieldName}</EmphasisLight>
    </div>
    <div className="fertilizer-plan-header-calc__data">
      <div className="fertilizer-plan-header__calculated-value">
        <p className="fertilizer-plan-header_calc-label">
          <MetaInformation size="small">{intl.formatMessage({ id: 'HEADER_AREA' })}: </MetaInformation>
          <span className="margin-right-padding"></span>
          <EmphasisLight>
            {fieldFertilizationItem.area.toFixed(1)} {fieldFertilizationItem.areaUnits}
          </EmphasisLight>
        </p>
      </div>
      <div className="fertilizer-plan-header__calculated-value">
        <p className="fertilizer-plan-header_calc-label">
          <MetaInformation size="small">{intl.formatMessage({ id: 'YIELD_GOAL' })}: </MetaInformation>
          <span className="margin-right-padding"></span>
          <EmphasisLight>
            {fieldFertilizationItem.soilParam.yieldGoal} {(fieldFertilizationItem.soilParam.cropId === 5 || fieldFertilizationItem.soilParam.cropId === 14 || 
              fieldFertilizationItem.soilParam.cropId === 17 || fieldFertilizationItem.soilParam.cropId === 18 || fieldFertilizationItem.soilParam.cropId === 19 || 
              fieldFertilizationItem.soilParam.cropId === 23) ? intl.formatMessage({ id: 'YIELD_UNIT' }) : 'kg ts/daa'}
          </EmphasisLight>
        </p>
      </div>
      <div className="fertilizer-plan-header__calculated-value">
        <p className="fertilizer-plan-header_calc-label">
          <MetaInformation size="small">{intl.formatMessage({ id: 'NUMBER_OF_HARVESTS' })}: </MetaInformation>
          <span className="margin-right-padding"></span>
          <EmphasisLight>{fieldFertilizationItem.numberOfHarvests}</EmphasisLight>
        </p>
      </div>
      <div className="fertilizer-plan-header__calculated-value">
        <p className="fertilizer-plan-header_calc-label">
          <MetaInformation size="small">{intl.formatMessage({ id: 'MANURE' })}: </MetaInformation>
          <span className="margin-right-padding"></span>
          <EmphasisLight>
            {fieldFertilizationItem.soilParam.manureApplied === true ? intl.formatMessage({ id: 'YES' }) : intl.formatMessage({ id: 'NO' })}
          </EmphasisLight>
        </p>
      </div>
      <div className="fertilizer-plan-header__calculated-value">
        <p className="fertilizer-plan-header_calc-label">
          <MetaInformation size="small">{intl.formatMessage({ id: 'NUMBER_OF_FERTILIZATIONS' })}: </MetaInformation>
          <span className="margin-right-padding"></span>
          <EmphasisLight>{fieldFertilizationItem.numberOfFertilizations}</EmphasisLight>
        </p>
      </div>
      <div className="fertilizer-plan-header__calculated-value">
        <p className="fertilizer-plan-header_calc-label">
          <MetaInformation size="small">{intl.formatMessage({ id: 'CT_CR' })}: </MetaInformation>
          <span className="margin-right-padding"></span>
          <EmphasisLight>{fieldFertilizationItem.soilParam.crop}</EmphasisLight>
        </p>
      </div>
    </div>
  </div>
);
