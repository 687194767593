/* eslint-disable react/jsx-props-no-spreading */
/**
 * Select box
 * require @props {disabled, options, styleName, onChange, defaultValue},
 * option will have  data for selection options.
 */
import React from 'react';
import Select, { components } from 'react-select';

import { EscapeRegExp } from 'utils';

const DropdownIndicator = props => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator {...props}>
      <span className={selectProps.menuIsOpen ? 'icon-arrow-up' : 'icon-arrow-down'} />
    </components.DropdownIndicator>
  );
};

const filterOption = (option, inputValue) => {
  if (option.label) {
    return (
      (option.label.toString().toLowerCase().match(EscapeRegExp(inputValue.toLowerCase())) || [])
        .length > 0
    );
  }
  return null;
};

const Selectbox = ({
  disabled,
  options,
  styleName,
  onChange,
  defaultValue,
  value,
  placeholder,
  menuPlacement="auto",
  menuIsOpen,
  id,
  isClearable
}) => (
  <Select
    options={options}
    className={styleName}
    menuPlacement={menuPlacement}
    value={value}
    isDisabled={disabled}
    placeholder={placeholder || ''}
    defaultValue={defaultValue}
    onChange={v => onChange(v)}
    menuIsOpen={menuIsOpen}
    inputId={id}
    components={{
      IndicatorSeparator: () => null,
      DropdownIndicator
    }}
    filterOption={filterOption}
    isClearable={isClearable}
  />
);

export default Selectbox;
