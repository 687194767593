export const modalTitleSubHeader = {
  color: 'var(--M3-sys-light-on-surface-variant, #3F4949)',
  fontFamily: 'Inter',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  padding: '20px 24px 0px 24px'
};
export const toggleMenu = {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  alignSelf: 'stretch'
}
export const toggleMenuContent = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  cursor: 'pointer'
}