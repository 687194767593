import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Header from 'mui-components/header';
import GrassAnalysis from 'mui-components/grassanalysis/grass';
import GrassAnalysisSearch from 'mui-components/grassanalysis/search';
import { GRASS_ANALYSIS, GRASS_ANALYSIS_SEARCH } from 'constants/index';
import { Box, Typography } from '@mui/material';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={index === 0 ? 'customFieldTabDiv' : 'customGrassTabDiv'}
    >
      {value === index && (
        <Box sx={{ padding: '0px 32px 0px 32px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class Storages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      value: 0
    };
  }

  componentDidMount = () => {
    const { location, activeFarm, history } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (location.pathname.includes(GRASS_ANALYSIS_SEARCH)) {
      this.setState({
        selected: GRASS_ANALYSIS_SEARCH,
        value: 1
      });
    } else {
      this.setState({
        selected: GRASS_ANALYSIS,
        value: 0
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { location} = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      let newSelected;
      let newValue;
  
      // Set the selected tab and value based on the current pathname
      if (location.pathname.includes(GRASS_ANALYSIS_SEARCH)) {
        newSelected = GRASS_ANALYSIS_SEARCH;
        newValue = 1;
      } else {
        newSelected = GRASS_ANALYSIS;
        newValue = 0;
      }
  
      // Update the state only if the selected value or the value state is different
      if (newSelected !== this.state.selected || newValue !== this.state.value) {
        this.setState({
          selected: newSelected,
          value: newValue
        });
      }
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    const { history } = this.props;    
    if(newValue === 1) {
      this.setState({ selected: GRASS_ANALYSIS_SEARCH, value: newValue });
      history.push("/grassanalysis/search");
    } else {
      this.setState({ selected: GRASS_ANALYSIS, value: newValue });
      history.push("/grassanalysis");
    }
  };

  addNewMineralFertilizer = () => {
    this.setState({
      openDialogWindow: true
    });
  }

  closeDialogWindow = () => {
    this.setState({
      openDialogWindow: false
    });
  }

  render() {
    const { intl } = this.props;
    const { value } = this.state;
    return (
      <Box className={`${'main-content'}`}>
        <Box >
          <Header bcTitle={intl.formatMessage({ id: 'GRASS_ANALYSIS' })} />
        </Box>
        <Box className="settings-tabs">
          <CustomTabPanel value={value} index={0}>
            <GrassAnalysis />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <GrassAnalysisSearch />
          </CustomTabPanel>
         </Box>
      </Box>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Storages)));