import React, { useRef, useState, useEffect } from "react";
import { TextField, InputAdornment } from '@mui/material';

const MuiTextField = (props) => {
  const { defaultLabel, defaultValue, inputAdornment, fieldName, onChange, autoFocus, helperText } = props;
  const [value, setValue] = useState(defaultValue || "");
  const textFieldRef = useRef(null);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      setValue("");
    }
  }, [defaultValue]);

  useEffect(() => {
    if (autoFocus && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [autoFocus]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(fieldName, e.target.value);
    }
  };

  return (
    <TextField
      inputRef={textFieldRef}
      variant="outlined"
      label={defaultLabel}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      error={!!helperText}
      helperText={helperText} 
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              color: '#6F7979',
              fontFamily: 'Inter',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '24px',
              letterSpacing: '0.5px'
            }}
          >
            {inputAdornment}
          </InputAdornment>
        )
      }}
      sx={{
        '& label': {
          color: '#3F4949',
          fontFamily: 'Inter'
        },
        '& label.Mui-focused': {
          color: '#00696D',
          fontFamily: 'Inter'
        }
      }}
    />
  );
};

export default MuiTextField;
