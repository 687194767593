/* eslint-disable react/jsx-props-no-spreading */
/**
 * Select Option Control
 * require @props {options}, option will have  data for selection options.
 * if select all option is there then remove the select all object from the return
 */
import React from 'react';
import Select, { components, Creatable } from 'react-select';

import AddIcon from '@tine/lib-frontend-components/elements/icons/add-icon/index';
import CheckboxButton from '@tine/lib-frontend-components/components/inputs/checkbox-button';

import { EscapeRegExp } from 'utils';
import { SelectAllOption } from 'constants/DropdownAndTranslationOptions';

// TO change default dropdown indicator
const DropdownIndicator = props => {
  const { selectProps, options } = props;
  const noOptionsFlag =
    selectProps.inputValue &&
    options &&
    options.some(
      item =>
        item.label.toString().toLowerCase() === selectProps.inputValue.toString().toLowerCase()
    );
  return (
    <components.DropdownIndicator {...props}>
      {selectProps.isCreatable ? (
        <>
          {((!noOptionsFlag && !selectProps.inputValue) || noOptionsFlag) && (
            <span className={selectProps.menuIsOpen ? 'icon-arrow-up' : 'icon-arrow-down'} />
          )}
          {!noOptionsFlag && selectProps.inputValue && (
            <span>
              <AddIcon scale={0.7} />
            </span>
          )}
        </>
      ) : (
        <span className={selectProps.menuIsOpen ? 'icon-arrow-up' : 'icon-arrow-down'} />
      )}
    </components.DropdownIndicator>
  );
};
// to filter values by label
const filterOption = (option, inputValue) =>
  (option.label.toString().toLowerCase().match(EscapeRegExp(inputValue.toLowerCase())) || [])
    .length > 0;

// Render option with checkbox
const Option = props => {
  const { isSelected, label, value } = props;
  return (
    <components.Option {...props}>
      <div className="select-option--checkbox">
        <CheckboxButton
          id={value}
          checked={isSelected}
          onChange={() => null}
          labelTextHidden
          labelText=""
        />
      </div>
      <span className="select-option--label">{label}</span>
    </components.Option>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const { getValue } = props;
  const currentValues = getValue();
  let toBeRendered = children;
  if (currentValues.some(val => val.value === SelectAllOption.value)) {
    /** 
     Uncomment to show all the selected option except select all
    const list = children[0];
    _.remove(children[0], { key: SelectAllOption.value }); 
    toBeRendered = [[list], children[1]];
    */
    toBeRendered = [[children[0][0]], children[1]];
  }
  return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>;
};

const MultiValue = props => {
  const { data, selectProps } = props;
  let labelToBeDisplayed = `${data.label}, `;
  if (data.value === SelectAllOption.value) {
    labelToBeDisplayed = selectProps.selectAllText;
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const SelectControl = ({
  input,
  label,
  disabled,
  options,
  controlId,
  styleName,
  defaultValue,
  placeholder,
  menuPlacement='auto',
  noOptionsMessage,
  controlledValue,
  isCreatable,
  isMulti,
  menuIsOpen,
  hasCheckbox,
  allowSelectAll,
  selectAllText,
  meta: { touched, error }
}) => {
  const ClassName = `form-group ${touched && error ? 'select-error' : ''}`;
  const inputProps = {};
  if (controlledValue) {
    inputProps.value = controlledValue;
    input.value = controlledValue;
  }
  let modifiedComponent = {
    IndicatorSeparator: () => null,
    DropdownIndicator
  };
  modifiedComponent = hasCheckbox ? { ...modifiedComponent, Option } : modifiedComponent;
  modifiedComponent =
    allowSelectAll && options?.length > 1
      ? { ...modifiedComponent, ValueContainer, MultiValue }
      : modifiedComponent;
  return (
    <div className={ClassName}>
      <label htmlFor={controlId} className="custom-lbl">
        {label}
      </label>
      {isCreatable ? (
        <Creatable
          {...input}
          {...inputProps}
          options={options}
          menuPlacement={menuPlacement}
          placeholder={placeholder}
          className={styleName}
          isDisabled={disabled}
          onChange={input.onChange}
          value={input.value ? input.value : defaultValue}
          onBlur={event => event.preventDefault()}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator
          }}
          clearable={false}
          disabled={disabled}
          noOptionsMessage={noOptionsMessage}
          filterOption={filterOption}
          isMulti={isMulti}
          isMenuOpen
          formatCreateLabel={userInput => `Add "${userInput}"`}
          isCreatable
          closeMenuOnSelect={!isMulti}
          menuIsOpen={menuIsOpen}
        />
      ) : (
        <Select
          {...input}
          {...inputProps}
          options={allowSelectAll && options?.length > 1 ? [SelectAllOption, ...options] : options}
          menuPlacement={menuPlacement}
          placeholder={placeholder}
          className={styleName}
          isDisabled={disabled}
          onChange={(selected, event) => {
            if (allowSelectAll && options.length > 1 && selected !== null && selected.length > 0) {
              if (selected[selected.length - 1].value === SelectAllOption.value) {
                return input.onChange([SelectAllOption, ...options]);
              }
              let result = [];
              if (selected.length === options.length) {
                if (selected.includes(SelectAllOption)) {
                  result = selected.filter(option => option.value !== SelectAllOption.value);
                } else if (event.action === 'select-option') {
                  result = [SelectAllOption, ...options];
                }
                return input.onChange(result);
              }
            }

            return input.onChange(selected);
          }}
          value={input.value ? input.value : defaultValue}
          onBlur={event => event.preventDefault()}
          components={modifiedComponent}
          clearable={false}
          disabled={disabled}
          noOptionsMessage={noOptionsMessage}
          filterOption={filterOption}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          menuIsOpen={menuIsOpen}
          hideSelectedOptions={isMulti && !hasCheckbox}
          selectAllText={selectAllText}
        />
      )}
      {touched && error && <span className="error-message">{error}</span>}
    </div>
  );
};

export default SelectControl;
