import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  ListItemText
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const MuiSelectDropdown = (props) => {
  const { defaultLabel, defaultValue, options, fieldName, onChange, required, errorMessage, deSelect=true } = props;
  const [value, setValue] = useState(defaultValue || "");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleSelect = (selectedValue) => {
    let newValue = "";
    if(deSelect) {
      newValue = selectedValue === value ? "" : selectedValue;
      setValue(newValue);
      if (required && newValue) {
        setError(false);
      }
      if (onChange) {
        onChange(fieldName, newValue);
      }
    } else {
      newValue = selectedValue;
    }
    if(!deSelect && newValue !== value) {
      setValue(newValue);
      if (required && newValue) {
        setError(false);
      }
      if (onChange) {
        onChange(fieldName, newValue);
      }
    } 
  };

  const handleValidation = () => {
    if (required && !value) {
      setError(true);
    }
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      sx={{
        "& label": { 
          color: "#3F4949", 
          fontFamily: "Inter", 
          transform: "translate(14px, 16px) scale(1)" // Adjust label position
        },
        "& label.Mui-focused": { 
          color: "#00696D", 
          fontFamily: "Inter" 
        },
        "& .MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)" // Adjust when focused
        }
      }}
    >
      <InputLabel>{defaultLabel}</InputLabel>
      <Select
        value={value || ""}
        onBlur={handleValidation}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={defaultLabel}
        displayEmpty
        InputLabelProps={{ shrink: true }}
        renderValue={(selected) => {
          // This will only render the selected value in the dropdown
          if (!selected) {
            return "";
          }
          const selectedOption = options.find((option) => option.value === selected);
          return selectedOption?.label || defaultLabel;
        }}
        MenuProps={{
          MenuListProps: {
            sx: {
              padding: 0 // Remove extra padding
            }
          }
        }}
      >
        {options &&
          options.map((option, index) => {
            // Find the last occurrence of the selected value
            const isLastOccurrence = index === options.map(o => o.value).lastIndexOf(value);
            return (
              <MenuItem
                key={index}
                value={option.value}
                onClick={() => handleSelect(option.value)}
                sx={{
                  display: "flex", // Ensures items align correctly
                  alignItems: "center",
                  justifyContent: "space-between" // Space between text and tick
                }}
              >
                <ListItemText primary={option.label} />
                {/* Show tick mark only for the last occurrence of the selected item */}
                {open && isLastOccurrence && option.value === value && (
                  <CheckIcon />
                )}
              </MenuItem>
            );
          })}
      </Select>
      {error && (
        <FormHelperText>
          {errorMessage || "This field is required"}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MuiSelectDropdown;
