import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { EanaCreateMap } from 'utils/map';
import * as fieldActions from 'actions/Fields';
import * as locationActions from 'actions/Geolocation';
import { ParseToPayloadObjFormat, StringifyToPolygonArrFormat } from 'utils/index';
import { GetLocalData, GetSessionData } from 'utils';
import { LOCAL_KEY_USER } from 'constants/index';

const FieldMap = new EanaCreateMap();
class FieldDraw extends React.Component {
  constructor(props) {
    super(props);
    this.fieldMapRef = React.createRef();
  }

  componentDidMount() {
    const { geoLocDetails, fieldCoords, fieldDetails, paramId, userInfo } = this.props;
    const { coOrds } = fieldCoords;
    const user = GetLocalData(LOCAL_KEY_USER) || GetSessionData(LOCAL_KEY_USER);
    if (geoLocDetails && fieldDetails) {
      const pointsToLocate = this.computeCoOrds()[0];
      const OtherFields = [...fieldDetails];
      _.remove(OtherFields, field => field.fieldId === paramId);
      FieldMap.updateLanguage(userInfo.data?.preferredLanguage || user.preferredLanguage);
      if(pointsToLocate) {
        FieldMap.drawPolygon(
          this.fieldMapRef.current.id,
          pointsToLocate.lattitude,
          pointsToLocate.longitude,
          coOrds ? JSON.stringify(StringifyToPolygonArrFormat(coOrds)) : null,
          JSON.stringify(OtherFields),
          (points, area) => this.getDrawnCoords(points, area)
        );
      }
    }
    //getGeoLocation();
  }

  computeCoOrds = () => {
    const { selectedField, geoLocDetails, activeFarm, paramId } = this.props;
    if (geoLocDetails.length > 0 && selectedField) {
      if (paramId === 'new') {
        if (activeFarm.farm.location === undefined || activeFarm.farm.location === null) {
          return geoLocDetails;
        }
        return [activeFarm.farm.location];
      }
      if (!selectedField?.gpsPoints || selectedField.gpsPoints.length === 0) {
        if (activeFarm.farm.location === undefined || activeFarm.farm.location === null) {
          return geoLocDetails;
        }
        return [activeFarm.farm.location];
      }
      return selectedField.gpsPoints;
    }
    return false;
  };

  recenter = () => {
    const { geoLocDetails } = this.props;
    if (geoLocDetails) {
      const pointsToLocate = geoLocDetails[0];
      FieldMap.viewToCurrentLocation(pointsToLocate.lattitude, pointsToLocate.longitude);
    }
  };

  getDrawnCoords = (points, area) => {
    const { clearMessages, saveFieldCoordinates } = this.props;
    saveFieldCoordinates({
      coOrds: points == null ? null : ParseToPayloadObjFormat(points),
      polygonDrawn: points !== null,
      area: area && (area * 1000).toFixed(2)
    });
    clearMessages();
  };

  render() {
    const { toggleScreen, mapId, fullScreen, title } = this.props;
    return (
      <div className={fullScreen ? 'full-screen-map-wrapper' : 'field-map'}>
        <div id={mapId} ref={this.fieldMapRef} style={{ height: '99%', width: '100%' }} />
        <div
          title="Reset Location"
          className="gps icon-directions"
          onClick={this.recenter}
          onKeyPress={this.recenter}
          role="button"
          tabIndex="-1"
          aria-label="locate"
        />
        <div
          title={title}
          className={`fullscreen icon-full-screen${fullScreen ? '-close' : ''}`}
          onClick={() => toggleScreen()}
          onKeyPress={() => toggleScreen()}
          role="button"
          tabIndex="-1"
          aria-label={title}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  geoLocDetails: state.geoLocDetails.data,
  fieldCoords: state.fieldDetails.savedFieldCoordinates,
  fieldDetails: state.fieldDetails.data,
  activeFarm: state.farmList.activeFarm,
  userInfo: state.userDetails
});

function mapDispatchToProps(dispatch) {
  return {
    getGeoLocation: () => dispatch(locationActions.getGeoLocation()),
    clearMessages: () => dispatch(fieldActions.clearMessages()),
    saveFieldCoordinates: obj => dispatch(fieldActions.saveFieldCoordinates(obj))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldDraw);
