import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import CheckboxButton from '@tine/lib-frontend-components/components/inputs/checkbox-button';
import fetchTaskUserImage from 'actions/ProfilePicture';
import {
  COMPLETED,
  ONGOING,
  PAUSED,
  ACT1,
  ACT7,
  ACT9,
  ACT10,
  ACT11,
  ACT12,
  ACT13,
  ACT16,
  ACT18,
  ACT20,
  ACT28,
  ACT30,
  ACT31,
  ACT32
} from 'constants/index';
import { SecondsTohoursMinutes, GetValuesFromDate, ParseISOString, PadString } from 'utils/index';

class TaskListItem extends Component {
  /**
   *  To pass object the checked status of the item
   * @param {object} e div element object
   */
  checkBoxChange = e => {
    const { handleCheckbox } = this.props;
    handleCheckbox(e);
  };

  /**
   * get the time formate to display
   *
   */
  getDurationFormate = () => {
    const { TaskDetails } = this.props;
    if (TaskDetails.netDuration) {
      const hrsMin = SecondsTohoursMinutes(TaskDetails.netDuration);
      return `${PadString(hrsMin.hours)} hrs ${PadString(hrsMin.minutes)} min`;
    }
    return null;
  };

  /**
   * get the end-time formate to display
   *
   */
   getEndTime = () => {
    const { TaskDetails } = this.props;
    if (TaskDetails.endTime) {
      const tempEndDateTime = TaskDetails.endTime
        ? GetValuesFromDate(new Date(ParseISOString(TaskDetails.endTime)))
        : { date: '', time: '' };
      return tempEndDateTime.date;
    }
    return null;
  };

  getAmount = () => {
    const { TaskDetails } = this.props;
    if (TaskDetails.taskActivity) {
      const amountUnit = TaskDetails.taskActivity;
      switch (amountUnit.activityId) {
        case ACT9:
        case ACT10:
        case ACT16:
        case ACT28:
          return {
            amount: amountUnit.cutAmount ? amountUnit.cutAmount : null,
            unit: amountUnit.cutUnit
          };
        case ACT11:
        case ACT12:
        case ACT18:
          return {
            amount: amountUnit.cutAmount ? amountUnit.cutAmount : null,
            unit: <FormattedMessage id="TASK_DYNAMIC_HARVEST_UNIT" />
          };
        case ACT20:
          return {
            amount: amountUnit.comboFertiAmount ? amountUnit.comboFertiAmount : null,
            unit: amountUnit.comboFertiUnit
          };
        case ACT13:
          return {
            amount: amountUnit.totalHarvest ? amountUnit.totalHarvest : null,
            unit: 'kg/daa'
          };
        case ACT1:
        case ACT7:
        case ACT30:
        case ACT31:
        case ACT32:
          return { amount: amountUnit.amount ? amountUnit.amount : null, unit: amountUnit.unit };
        default:
          return null;
      }
    }
    return null;
  };

  render() {
    const { TaskDetails, checked, isLoading, hasPermission, intl } = this.props;
    const status = TaskDetails.status ? TaskDetails.status.toUpperCase() : null;
    const amountAndUnit = this.getAmount();
    const isDisabled = isLoading && 'disable';
    let seasonName = null;
    if(TaskDetails.seasonId) {
      if(TaskDetails.seasonId === 1) {
        seasonName = intl.formatMessage({ id: 'CHOOSE_FERTILIZATION' });
      } else if(TaskDetails.seasonId === 2) {
        seasonName = intl.formatMessage({ id: 'CHOOSE_FIRST_HARVEST' });
      } else if(TaskDetails.seasonId === 3) {
        seasonName = intl.formatMessage({ id: 'CHOOSE_SECOND_HARVEST' });
      } else if(TaskDetails.seasonId === 4) {
        seasonName = intl.formatMessage({ id: 'CHOOSE_THIRD_HARVEST' });
      } else {
        seasonName = null;
      }
    }
    return (
      <li
        className={'task-list-item show-checkbox full-width'}
        key={TaskDetails.id}
      >
        <div className="checkbox-holder">
          <span>
            <CheckboxButton
              checked={checked}
              labelTextHidden
              labelText=""
              disabled={isLoading || !(hasPermission.deletetask || hasPermission.updateTask)}
              id={TaskDetails.id}
              onChange={e => this.checkBoxChange(e)}
            />
          </span>
        </div>
        <Link
          className={
            status && status === COMPLETED
              ? `task-link green-bdr ${isDisabled}`
              : `task-link ${isDisabled}`
          }
          to={`/tasks/tasklist/${TaskDetails.id}`}
        >
          <div className="task-details">
            <div className="task-field-image task-right-border">
              <span className="task-filed-tile ellipsis-text">
                <span className="field-name ellipsis-text">
                <Emphasis size="large">
                  {TaskDetails.fieldId ? (
                    TaskDetails.fieldName
                  ) : (
                    <FormattedMessage id="NOT_DEFINED" />
                  )}
                </Emphasis>
                {TaskDetails.fieldArea ? (
                  <span className="padding-left-small">({TaskDetails.fieldArea})</span>
                ) : ''}
                </span>
              </span>
            </div>
            <div className="task-assignee task-right-border">
              <span className="task-name ellipsis-text">
                {status && (status === ONGOING || status === PAUSED) ? (
                  <i className="icon-record" />
                ) : null}
                <Emphasis size="large">
                  {TaskDetails.activityId ? (
                    TaskDetails.activityName
                  ) : (
                    <FormattedMessage id="NOT_DEFINED" />
                  )}
                </Emphasis>
              </span>
              <span className="item-value1 ellipsis-text">
                {seasonName !== null ? seasonName : (TaskDetails.cut !== null ? TaskDetails.cut : '')}
              </span>
            </div>
            <div className="task-status task-right-border status">
              <span className="item-lbl">
                <FormattedMessage id="STATUS" />:
              </span>
              <span className="item-value ellipsis-text">
                {TaskDetails.status ? (
                  <FormattedMessage id={status} />
                ) : (
                  null
                )}
              </span>
            </div>
            <div className="task-status task-right-border duration">
              <span className="item-lbl">
                <FormattedMessage id="COMPLETED" />:
              </span>
              <span className="item-value ">
                {TaskDetails.endTime ? (
                  this.getEndTime()
                ) : (
                  <span className="item-value">--</span>
                )}
              </span>
            </div>
            <div className="task-status task-right-border duration">
              <span className="item-lbl">
                <FormattedMessage id="DURATION" />:
              </span>
              <span className="item-value ">
                {status === COMPLETED ? this.getDurationFormate() : null}
              </span>
            </div>
            <div className="task-status amount">
              <span className="item-lbl">
                <FormattedMessage id="AMOUNT" />:
              </span>
              {amountAndUnit && amountAndUnit.amount ? (
                <span
                  title={`${amountAndUnit.amount} ${amountAndUnit.unit}`}
                  className="item-value ellipsis-text"
                >
                  {amountAndUnit.amount} {amountAndUnit.unit}
                </span>
              ) : (
                <span className="item-value">--</span>
              )}
            </div>
          </div>
        </Link>
      </li>
    );
  }
}

const mapStateToProps = state => ({
  /*AWSLoginDetails: state.AWSLogin.data,*/
  profilePictures: state.profileImage.userImage
});

const mapDispatchToProps = dispatch => ({
  getTaskUserImage: (AWSLogin, type, id, userType) => dispatch(fetchTaskUserImage(AWSLogin, type, id, userType))
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskListItem);
