export const scrollUpAnimation = {
  position: 'absolute',
  top: '68px',
  right: '20px',
  width: '350px',
  height: '80vh',
  background: '#F7FAF9',
  zIndex: '10',
  transform: 'translateX(0)',
  transition: 'transform 0.5s ease-out',
  borderRadius: '0px 17px 17px 0px',
  border: '1px solid #BEC8C9'
};
export const modalTitle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '22px 12px 16px 24px',
  position: 'fixed',
  top: 0,
  zIndex: 10,
  backgroundColor: '#F7FAF9',
  borderTopRightRadius: '20px',
  width: '100%'
};
export const modalTitleHeader = {
  color: 'var(--M3-sys-light-on-surface-variant, #3F4949)',
  fontFamily: 'Inter',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '28px'
};
export const modalTitleSubHeader = {
  color: 'var(--M3-sys-light-on-surface-variant, #3F4949)',
  fontFamily: 'Inter',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '16px',
  letterSpacing: '0.5px'
};
export const lastUsedBox = {
  display: 'flex',
  height: '56px',
  padding: '8px 24px',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
  cursor: 'pointer'
};
export const lastUsedBoxLeft = {
  display: 'flex',
  height: '56px',
  padding: '8px 24px',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
  cursor: 'pointer'
};
export const lastUsedBoxTitle = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px'
};
export const borderBottomBox = {
  display: 'flex',
  height: '30px',
  padding: '8px 20px',
  alignItems: 'center',
  gap: '12px'
};
export const toggleSettings = {
  display: 'flex',
  height: '56px',
  padding: '8px 12px',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
  width: '100%',
  paddingTop: '20px',
  paddingBottom: '20px',
  cursor: 'pointer'
}
export const toggleLeftHeading = {
  color: 'var(--M3-sys-light-on-surface-variant, #3F4949)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  width: '95%'
}
export const toggleRightHeading = {
  display: 'flex',
  width: '24px',
  height: '24px',
  justifyContent: 'center',
  alignItems: 'center'
}