import { USER_ACTIVITIES_URL } from 'constants/ApiUrl';
import {
  KART_TOGGLE_LOADING,
  KART_TOGGLE_SUCCESS,
  KART_TOGGLE_FAILURE
} from 'constants/store/UserActivities';
import apiRequest from 'utils/ApiRequest';

export const updateKartToggle = (farmId, data) => async (dispatch) => {
  dispatch({ type: KART_TOGGLE_LOADING, payload: true });
  await dispatch(
    apiRequest({
      url: `${USER_ACTIVITIES_URL}/${farmId}`,
      method: 'put',
      data
    })
  )
    .then(response => {
      dispatch({ type: KART_TOGGLE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: KART_TOGGLE_FAILURE, payload: error });
    });
};