import {
  SAVE_TASK_KART_TOGGLE_LOADING,
  SAVE_TASK_KART_TOGGLE_SUCCESS,
  SAVE_TASK_KART_TOGGLE_FAILURE,
  GET_TASK_KART_TOGGLE_LOADING,
  GET_TASK_KART_TOGGLE_SUCCESS,
  GET_TASK_KART_TOGGLE_FAILURE
} from 'constants/store/UserActivities';

const initialState = {
  saveHasError: false,
  saveIsLoading: false,
  saveErrorMessage: null,
  getHasError: false,
  getIsLoading: false,
  getErrorMessage: null,
  getTaskList: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SAVE_TASK_KART_TOGGLE_LOADING:
      return {
        ...state,
        saveHasError: false,
        saveIsLoading: true,
        saveErrorMessage: null,
      };
    case SAVE_TASK_KART_TOGGLE_FAILURE:
      return {
        ...state,
        saveHasError: true,
        saveIsLoading: false,
        saveErrorMessage: action.payload,
      };
    case SAVE_TASK_KART_TOGGLE_SUCCESS:
      return {
        ...state,
        saveHasError: false,
        saveIsLoading: false,
        saveErrorMessage: null,
      };
    case GET_TASK_KART_TOGGLE_LOADING:
      return {
        ...state,
        getHasError: false,
        getIsLoading: true,
        getErrorMessage: null,
        getTaskList: null
      };
    case GET_TASK_KART_TOGGLE_FAILURE:
      return {
        ...state,
        getHasError: true,
        getIsLoading: false,
        getErrorMessage: action.payload,
        getTaskList: null
      };
    case GET_TASK_KART_TOGGLE_SUCCESS:
      return {
        ...state,
        getHasError: false,
        getIsLoading: false,
        getErrorMessage: null,
        getTaskList: action.payload
      };
    default:
      return state;
  }
}