import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter, Redirect } from 'react-router-dom';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import CheckboxButton from '@tine/lib-frontend-components/components/inputs/checkbox-button';
import BaleMap from 'mui-components/storages/balestorage/balestorage/BaleMap';
import TextAreaControl from 'components/forms/TextAreaControl';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import Header from 'mui-components/header';
import TextBoxControl from 'components/forms/TextBoxControl';
import * as baleActions from 'actions/Bale';
import mixpanel from 'mixpanel-browser';
import { ADD_BALE_STORAGE, ADD_BALE_STORAGE_COMPLETE } from 'constants/MixPanel';
import { Box } from '@mui/material';

class BaleStorageForm extends React.Component {
  state = {
    sortField: false,
    showErrorCoordinates: false
  };

  componentDidMount = () => {
    const { getBalesList, userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(ADD_BALE_STORAGE);
    getBalesList.markerSaveSuccess = false;
    this.setSavedCoordinates();
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/storages');
    }
  }

  setSavedCoordinates = () => {
    const { saveBaleMarkerCoordinates } = this.props;
    saveBaleMarkerCoordinates(null);
  };

  checkBoxChange = e => {
    this.setState(prevState => ({
      sortField: !prevState.sortField
    }));
    this.setState({
      showErrorCoordinates: false
    });
  };

  handleSubmit = values => {
    const { activeFarm, addBaleMarker, getBalesList, userDetails } = this.props;
    const { sortField } = this.state;
    this.setState({ showErrorCoordinates: false });
    if(getBalesList && getBalesList.savedBaleMarkerCoordinates !== null && getBalesList.savedBaleMarkerCoordinates.latitude !== null) {
      const formData = {
        "name": values.baleName,
        "farmId": activeFarm.farm.id,
        "sortByField": sortField,
        "gpsPoint": {
            "latitude": getBalesList.savedBaleMarkerCoordinates.latitude,
            "longitude": getBalesList.savedBaleMarkerCoordinates.longitude
        },
        "note": values.baleNote
      }
      mixpanel.identify(userDetails.id);
      mixpanel.track(ADD_BALE_STORAGE_COMPLETE);
      addBaleMarker(activeFarm.farm.id, formData);
    } else {
      this.setState({
        showErrorCoordinates: true
      });
    }
  };

  render() {
    const { handleSubmit, getBalesList, intl } = this.props;
    const { showErrorCoordinates } = this.state;
    return (
      <Box className={`${'main-content'}`}>
        <Box>
          <Header
            bcLink={{ to: '/storages', text: intl.formatMessage({ id: 'BALE_STORAGE_HEADER' }) }}
            bcTitle={intl.formatMessage({ id: 'CREATE_BALE_STORAGE_TITLE' })}
            bcLinkArrow={true}
          />
        </Box>
        <Box className="settings-tabs">
          <div className="full-width edit-field-wrap">
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              <div className="edit-field-container">
                <div className="field-area">
                  <BaleMap
                    title="Full screen"
                    mapId="off-screen-map-bale-new"
                    paramId="new"
                    mapData={null}
                  />
                </div>
                <div className="field-settings">
                  <div className="field-settings-form">
                    <div className="padding-bottom-div font-style-div">
                      <Field
                        name="baleName"
                        label={intl.formatMessage({ id: 'CREATE_BALE_NAME' })}
                        component={TextBoxControl}
                        controlId="baleName"
                        type="text"
                        stlyeClass="font-style-div"
                      />
                    </div>
                    <div className="font-style-div padding-bottom-div">
                      <span className="location-type-checkbox">
                        <CheckboxButton
                          id="baleCheck"
                          label="Lagerplassen"
                          checked={this.state.sortField}
                          labelText={intl.formatMessage({ id: 'CREATE_BALE_SORT_FIELD' })}
                          onChange={e => this.checkBoxChange(e)}
                          stlyeClass="font-style-div"
                        />
                      </span>
                    </div>
                    <div className="fieldNote padding-top-div full-width">
                      <Field
                        name="baleNote"
                        label={intl.formatMessage({ id: 'CREATE_BALE_NOTE' })}
                        component={TextAreaControl}
                        controlId="baleNote"
                        stlyeClass="textarea-placeholder-size"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="field-form-submit-wrap">
                <div className="bale-error">
                  {showErrorCoordinates && <ErrorText>{intl.formatMessage({ id: 'CREATE_BALE_MARKER_ERROR' })}</ErrorText>}
                  {getBalesList && getBalesList.markerHasError && <ErrorText>{getBalesList.markerErrorMessage}</ErrorText>}
                  {getBalesList && getBalesList.markerSaveSuccess && <Redirect to="/storage" />}
                </div>
                <div>
                  <ActionButton onClick={() => handleSubmit(this.handleSubmit)}>
                    {intl.formatMessage({ id: 'EDIT_BALE_STORAGE_BUTTON' })}
                  </ActionButton>
                </div>
              </div>
            </form>
          </div>
         </Box>
      </Box>
    );
  }
}
const validate = (values, props) => {
  const { intl } = props;
  const errors = {};
  if (!values.baleName || !values.baleName.trim()) {
    errors.baleName = intl.formatMessage({ id: 'CREATE_BALE_NAME_ERROR' });
  }

  return errors;
};

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  getBalesList: state.bales,
  userDetails: state.userDetails.data
});

const mapDispatchToProps = dispatch => ({
  addBaleMarker: (farmId, data) => dispatch(baleActions.addBaleMarker(farmId, data)),
  saveBaleMarkerCoordinates: obj => dispatch(baleActions.saveBaleMarkerCoordinates(obj))
});

export default injectIntl(
  reduxForm({
    form: 'BaleStorageForm',
    validate
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(BaleStorageForm)))
);
