export const USER_ACTIVITIES_LOADING = 'USER_ACTIVITIES_LOADING';
export const USER_ACTIVITIES_SUCCESS = 'USER_ACTIVITIES_SUCCESS';
export const USER_ACTIVITIES_FAILURE = 'USER_ACTIVITIES_FAILURE';

export const KART_TOGGLE_LOADING = 'KART_TOGGLE_LOADING';
export const KART_TOGGLE_SUCCESS = 'KART_TOGGLE_SUCCESS';
export const KART_TOGGLE_FAILURE = 'KART_TOGGLE_FAILURE';

export const SAVE_TASK_KART_TOGGLE_LOADING = 'SAVE_TASK_KART_TOGGLE_LOADING';
export const SAVE_TASK_KART_TOGGLE_SUCCESS = 'SAVE_TASK_KART_TOGGLE_SUCCESS';
export const SAVE_TASK_KART_TOGGLE_FAILURE = 'SAVE_TASK_KART_TOGGLE_FAILURE';

export const GET_TASK_KART_TOGGLE_LOADING = 'GET_TASK_KART_TOGGLE_LOADING';
export const GET_TASK_KART_TOGGLE_SUCCESS = 'GET_TASK_KART_TOGGLE_SUCCESS';
export const GET_TASK_KART_TOGGLE_FAILURE = 'GET_TASK_KART_TOGGLE_FAILURE';
