
export const getApiUrl = () => {
    if (window.location.hostname === "localhost") {
        return "https://field-assistant.openfarm-dev.io/";
    }
    if (window.location.hostname.includes("dev")) {
        return "https://field-assistant.openfarm-dev.io/";
    }
    return "https://field-assistant.openfarm.io/";
}

// API end point
export const API_URI = getApiUrl();
export const API_ENDPOINT = `api/v1/`;
export const API_ENDPOINT_V2 = `api/v2/`;
export const API_ENDPOINT_V3 = `api/v3/`;
export const API_ENDPOINT_V4 = `api/v4/`;

// Auth

export const AUTH = `${API_URI}${API_ENDPOINT}users`;

// terms and conditions
export const TERMS_CHECK = `${API_URI}privacy/toc/registrations/check-registration`;
export const GET_TERMS = `${API_URI}privacy/toc/registrations?language=`;
export const ACCEPT_TERM = `${API_URI}privacy/toc/registrations/users`;

// Task releated apis
export const TASK = `${API_ENDPOINT}tasks`;
export const TASK_LIST = `${API_ENDPOINT_V2}tasks/offsets?farmId=`;
export const TASK_DELETE = `${API_ENDPOINT}tasks/`;
export const DOWNLOAD_REPORT = `${API_ENDPOINT}tasks/farms/`;

// Users
export const USERS = `${API_ENDPOINT}userroles`;
export const USERS_V2 = `${API_ENDPOINT_V2}userroles`;
export const USER_ROLES = `${API_ENDPOINT}roles`;

// Farms
export const FARM_V3 = `${API_ENDPOINT_V3}farms`;
export const FARM_V2 = `${API_ENDPOINT_V2}farms`;
export const FARM_V1 = `${API_ENDPOINT}farms`;
export const FARM_LIST = `${API_ENDPOINT}userroles/farms`;
export const FARM_DELETE_CHECK = `${API_ENDPOINT}farms/check/`;
export const SUBSCRIPTION_URL = `${API_ENDPOINT}farms/subscription-history`;
export const USER_ACTIVITIES_URL = `${API_ENDPOINT}activities/farms`;
export const KART_URL_V4 = `${API_ENDPOINT_V4}farms`;

// Fields
export const FIELD_V1 = `${FARM_V1}/`;
export const FIELD_V2 = `${FARM_V2}/`;
export const FIELD_V3 = `${FARM_V3}/`;

// Assets
export const GET_ASSETS_TYPE = `${API_ENDPOINT}assets/getAssetType/`;
export const GET_ASSET_TYPE_ENTITY = `${API_ENDPOINT}assets/getAssetEntity/`;
export const CREATE_ASSET = `${API_ENDPOINT}assets/create/`;
export const UPDATE_ASSET = `${API_ENDPOINT}assets/update/`;
export const DELETE_ASSET = `${API_ENDPOINT}assets/deleteAsset/`;
// Stats
export const GET_STATS = `${API_ENDPOINT}kpis/farms/`;

// Roles permissions
export const ROLES_PERMISSION = `${API_ENDPOINT}rolePermission`;

// Activity list
export const ACTIVITY_LIST = `${API_ENDPOINT}activities`;

// Master data
export const MASTER_DATA = `${API_ENDPOINT}activities/masterdata`;

// Fertilization
export const MANURE_STORAGE_TYPE_URL = `${API_ENDPOINT}farms/manurestorages/types`;
export const SOIL_SAMPLES = `${API_ENDPOINT}soiltests/farms/`;
export const SOIL_SAMPLES_NEW = `${API_ENDPOINT}soilsamples/farms/`;
export const FERTILIZATION_PLAN = `${API_ENDPOINT}fertilization/`;
export const FIELD_METHOD = `${API_ENDPOINT}farms/fertilizationmethods`;
export const MINERAL_FERTILIZER_STORAGE = `${API_ENDPOINT}farms`;

// Weather
export const FIELD_WEATHER = `${API_ENDPOINT}weather/farms/`;

// Crop
export const CROP_URL = `${API_ENDPOINT}farms/fields/crops`;

// Previous Crop
export const PREVIOUS_CROP_URL = `${API_ENDPOINT}farms/fields/grain-crops`;

// Yield Plan
export const YIELD_PLAN_URL = `${API_ENDPOINT}farms/fields/yieldplans`;

// Grass Analysis
export const GRASS_ANALYSIS_URL = `${API_ENDPOINT}grass-analysis`;

export const GRASS_ANALYSIS_SEARCH_URL = `${API_ENDPOINT}farms/grass-analysis/qrcodes`;

// Grass Analysis version-2
export const GRASS_ANALYSIS_URL_VERSION_2 = `${API_ENDPOINT_V2}grass-analysis`;

export const HISTORICAL_DATA_URL = `${API_ENDPOINT}harvestdates/farms`;

export const HISTORICAL_LIST_URL = `${API_ENDPOINT_V4}farms`;

// Field Soil Samples
export const FIELD_SOIL_SAMPLES_URL = `${FARM_V1}/`;

export const TASK_BATCH_URL = `${API_ENDPOINT_V4}farms`;

export const OMD_GOAL_URL = `${API_ENDPOINT}weather/omdgoals/farms`;