import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import SoilSamplesListCard from 'mui-components/fields/fieldtab/soilsamples/SoilSamplesListCard';
import { NEW } from 'constants/index';
import * as fieldSoilSampleActions from 'actions/FieldSoilSamples';
import _ from 'lodash';
import { DecimalValidation, IntegerValidation } from 'utils/index';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { buttonRoundStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';
import { Stack, Box, Chip, Button } from '@mui/material';
import { PlusIcon } from 'components/mui-forms/MuiIcons';
import { ADD_SOIL_SAMPLE } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';
import * as fieldActions from 'actions/Fields';

class SoilSamplesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramId: props.match.params.id,
      showSoilSampleModal: false,
      isCreate: true,
      showDelete: false,
      selectedId: null,
      isView: false,
      updatedFields: false,
      previousIndex: null,
      nextIndex: null,
      previousIndexName: null,
      nextIndexName: null,
      selectedFieldId: null
    };
    this.openShowSoilSampleModal = this.openShowSoilSampleModal.bind(this);
    this.closeShowSoilSampleModal = this.closeShowSoilSampleModal.bind(this);
  }

  componentDidMount = () => {
    const { history, activeFarm, getSoilSamples, fetchFieldList } = this.props;
    const { paramId } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    } else {
      getSoilSamples(activeFarm.farm.id, paramId);
    }
    if (activeFarm) {
      fetchFieldList(activeFarm.farm.id);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { history, fieldDetails, activeFarm, getSoilSamples, fieldSoilSamples, location } = this.props;
    const { selectedFieldId, updatedFields } = this.state;
    const { paramId } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (!updatedFields && fieldDetails && fieldDetails !== 'undefined') {
      //getSoilSamples(activeFarm.farm.id, selectedFieldId !== null ? selectedFieldId : paramId);
      this.intializeValue(selectedFieldId !== null ? selectedFieldId : paramId);
    }
    if (location !== prevProps.location) {
      getSoilSamples(activeFarm.farm.id, selectedFieldId !== null ? selectedFieldId : paramId);
      this.intializeValue(selectedFieldId !== null ? selectedFieldId : paramId);
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push(`/managefields/${paramId}`);
      getSoilSamples(activeFarm.farm.id, selectedFieldId !== null ? selectedFieldId : paramId);
      this.intializeValue(selectedFieldId !== null ? selectedFieldId : paramId);
    }
    if(fieldSoilSamples.isSuccess) {
      fieldSoilSamples.isSuccess = false;
      //this.closeShowSoilSampleModal();
      window.location.href = `#/managefields/${this.props.match.params.id}/soilsamples`;
      window.location.reload();
    }
  };

  // Intialize compoenent values by passing url param Id
  intializeValue = paramId => {
    const { fieldDetails } = this.props;
    this.setState({ updatedFields: true });
    if (paramId !== NEW) {
      const currentField = _.filter(fieldDetails.fields, ['fieldId', paramId]);
      //let fieldDetailsSorted = _.orderBy(fieldDetails, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']);
      let fieldDetailsSorted = fieldDetails.fields;
      const currentIndex = fieldDetailsSorted.findIndex( fieldDetail => fieldDetail.fieldId === paramId );
      if (currentField.length > 0) {
        this.setState({
          selectedField: currentField[0],
          currentFieldValue: {...currentField[0]}
        });
        if(currentIndex === 0 && currentIndex === fieldDetailsSorted.length-1) {
          this.setState({ 
            previousIndex: null,
            nextIndex: null,
            previousIndexName: null,
            nextIndexName: null
          });
        }
        if(currentIndex === 0 && currentIndex !== fieldDetailsSorted.length-1) {
          this.setState({ 
            previousIndex: null,
            nextIndex: fieldDetailsSorted[currentIndex+1].fieldId,
            previousIndexName: null,
            nextIndexName: fieldDetailsSorted[currentIndex+1].displayName
          });
        }
        if(currentIndex !== 0 && currentIndex === fieldDetailsSorted.length-1) {
          this.setState({ 
            previousIndex: fieldDetailsSorted[currentIndex-1].fieldId,
            nextIndex: null,
            previousIndexName: fieldDetailsSorted[currentIndex-1].displayName,
            nextIndexName: null
          });
        }
        if(currentIndex !== 0 && currentIndex !== fieldDetailsSorted.length-1) {
          this.setState({ 
            previousIndex: fieldDetailsSorted[currentIndex-1].fieldId,
            nextIndex: fieldDetailsSorted[currentIndex+1].fieldId,
            previousIndexName: fieldDetailsSorted[currentIndex-1].displayName,
            nextIndexName: fieldDetailsSorted[currentIndex+1].displayName
          });
        }
      }
    }
  };

  setSoilSampleInitialize = () => {
    const { initialize, fieldSoilSamples } = this.props;
    fieldSoilSamples.hasError = false;
    const years = [];
    years.push({ label: "", value: "" });
    const formValues = {
      year: years,
      soilType: null,
      moldClass: null,
      phosphorus: null,
      potassium: null,
      acidSolublePotassium: null,
      ph: null,
    };
    initialize(formValues);
  };

  updateInitialize = (selectedSoilSample) => {
    const { initialize, fieldSoilSamples } = this.props;
    fieldSoilSamples.hasError = false;
    const years = [];
    years.push({ label: selectedSoilSample[0].year, value: selectedSoilSample[0].year });
    const formValues = {
      year: years,
      soilType: (selectedSoilSample[0].soilType !== 0 && selectedSoilSample[0].soilType !== null) ? selectedSoilSample[0].soilType : '',
      moldClass: (selectedSoilSample[0].moldClass !== 0 && selectedSoilSample[0].moldClass !== null) ? selectedSoilSample[0].moldClass : '',
      phosphorus: (selectedSoilSample[0].phosphorus !== 0 && selectedSoilSample[0].phosphorus !== null) ? selectedSoilSample[0].phosphorus : '',
      potassium: (selectedSoilSample[0].potassium !== 0 && selectedSoilSample[0].potassium !== null) ? selectedSoilSample[0].potassium : '',
      acidSolublePotassium: (selectedSoilSample[0].acidSolublePotassium !== 0 && selectedSoilSample[0].acidSolublePotassium !== null) ? selectedSoilSample[0].acidSolublePotassium : '',
      ph: (selectedSoilSample[0].pH !== 0 && selectedSoilSample[0].pH !== null) ? selectedSoilSample[0].pH : ''
    };
    if(selectedSoilSample[0].source === 'Importert fra fil') {
      this.setState({
        isView: true
      })
    }
    initialize(formValues);
  }

  openShowSoilSampleModal = (selectedId) => {
    const { fieldSoilSamples } = this.props;
    const selectedSoilSample = _.filter(fieldSoilSamples.getFieldSoilSampleData, ['id', selectedId]);
    this.setState({
      showSoilSampleModal: true,
      selectedId: selectedId,
      isView: false
    });
    if(selectedSoilSample.length > 0) {
      this.updateInitialize(selectedSoilSample);
    }
  }

  closeShowSoilSampleModal = () => {
    this.setState({
      showSoilSampleModal: false
    });
    this.setSoilSampleInitialize();
  }

  handleSubmit = values => {
    const { activeFarm, addSoilSample, updateSoilSample } = this.props;
    const { selectedId } = this.state;
    let paramId = this.props.match.params.id;
    let formData = {};
    formData["soilType"] = values.soilType;
    formData["moldClass"] = values.moldClass;
    formData["phosphorus"] = values.phosphorus;
    formData["potassium"] = values.potassium;
    formData["acidSolublePotassium"] = values.acidSolublePotassium;
    formData["pH"] = values.ph;
    if(selectedId !== null) {
      formData["year"] = values.year[0] ? values.year[0].value : values.year ? values.year.value : '';
      updateSoilSample(activeFarm.farm.id, paramId, formData, selectedId);
    } else {
      formData["year"] = values.year ? values.year.value : '';
      addSoilSample(activeFarm.farm.id, paramId, formData);
    }
  }

  showDialog = action => {
    const { deleteSoilSample, activeFarm } = this.props;
    const { paramId, selectedId } = this.state;
    if (action) {
      deleteSoilSample(activeFarm.farm.id, paramId, selectedId);
    }
    this.setState({ showDelete: false });
  };

  changeField = (fieldId) => {
    const { history } = this.props;
    this.setState({
      selectedFieldId: fieldId
    });
    history.push(`/managefields/${fieldId}/soilsamples`);
  }

  render() {
    const { intl, activeFarm, fieldSoilSamples, handleSubmit, userDetails, addNewSoilSample } = this.props;
    const { previousIndex, nextIndex, previousIndexName, nextIndexName } = this.state;
    let newParamId = this.props.match.params.id;
    const soilSamplesLength = fieldSoilSamples && fieldSoilSamples.getFieldSoilSampleData && fieldSoilSamples.getFieldSoilSampleData.length;
    const centerAlignStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '72px',
      zIndex: 1
    };
    const linkStyle = { color: '#00696D', fontSize: '14px', fontFamily: 'Inter', textTransform: 'none' };
    const linkStyleDisabled = { color: '#cccccc', fontSize: '14px', fontFamily: 'Inter', textTransform: 'none' };
    const linkContainerStyle = { display: 'flex', alignItems: 'center' };
    const arrowStyle = { color: '#00696D', width: '18px', height: '18px' };
    const arrowStyleLeftDisabled = { color: '#cccccc', width: '18px', height: '18px', marginRight: 10 };
    const arrowStyleRightDisabled = { ...arrowStyleLeftDisabled, marginLeft: 10 };
    const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', width: '340px' };
    const titleFont = {
      color: '#191C1C',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '32px'
    }
    const subTitleFont = {
      color: '#3F4949',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px'
    }
    return (
      <div className="asset-list-wrapper">
        {fieldSoilSamples.getFieldSoilSampleData && fieldSoilSamples.getFieldSoilSampleData.length === 0 ?
          (
            <div className={`manageFarmsLayoutDivLayout`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '60vh', paddingBottom: '20px' }}>
              <div className={`manageFarmsLayoutDiv`}>
                <div><img src="images/NoSoilParams.png" alt="No Soil Parameters" width="120" /></div>
                <Box style={inputStyle}>
                  <Stack className="text-with-padding">
                    <div style={titleFont}>
                      {intl.formatMessage({ id: 'SOIL_SAMPLES' })}
                    </div>
                  </Stack>
                  <Stack className="text-with-padding">
                    <div style={subTitleFont}>
                      {intl.formatMessage({ id: 'NO_SOIL_SAMPLES_CONTENT' })}
                    </div>
                  </Stack>
                  <div style={{ padding: '20px 0px', paddingRight: '0px !important' }}>
                    <Stack direction="row" justifyContent="center">
                      <Chip
                        icon={<PlusIcon />}
                        label={intl.formatMessage({ id: 'ADD_FIELD_SOIL_SAMPLES' })}
                        color="primary"
                        variant="outlined"
                        clickable
                        onClick={() => {mixpanel.identify(userDetails.id); mixpanel.track(ADD_SOIL_SAMPLE); addNewSoilSample();}}
                        style={buttonRoundStyle}
                      />
                    </Stack>
                  </div>
                </Box>
              </div>
            </div>
          ) :
          <div style={{ minHeight: '65vh', paddingBottom: '20px' }}>
            {fieldSoilSamples.getFieldSoilSampleData?.length >= 1 &&
            fieldSoilSamples.getFieldSoilSampleData.map((soilSamples, index) => (
              <Box sx={{ width: '100%', flexGrow: 1 }}>
                <SoilSamplesListCard
                  soilSamples={soilSamples}
                  farm={activeFarm}
                  key={soilSamples.id}
                  disable={fieldSoilSamples.isLoading}
                  handleSubmit={handleSubmit(this.handleSubmit)}
                  openShowSoilSampleModal={this.openShowSoilSampleModal}
                  newParamId={newParamId}
                  soilSamplesLength={soilSamplesLength}
                  index={index}
                />
              </Box>
            ))}
          </div>
        }
        {fieldSoilSamples.getFieldSoilSampleData?.length >= 0 &&
        <div style={centerAlignStyle} className={`${'fixed-button-mui'}`}>
          {previousIndex !== null ? 
          (newParamId !== NEW ? <div style={linkContainerStyle}>
            <ArrowBackIcon style={arrowStyle} />
            <Button style={linkStyle} onClick={() => this.changeField(previousIndex)} type="submit">
              {previousIndexName}
              {/*intl.formatMessage({ id: 'PREVIOUS_FIELD' })*/}
            </Button>
          </div> : <div style={linkContainerStyle}>&nbsp;</div>) :
          (newParamId !== NEW ? <div style={linkContainerStyle} sx={{disabled: true}}>
            <ArrowBackIcon style={arrowStyleLeftDisabled} />
            <span style={linkStyleDisabled}>{intl.formatMessage({ id: 'PREVIOUS_FIELD' })}</span>
          </div> : <div style={linkContainerStyle}>&nbsp;</div>)}
          {nextIndex !== null ?
          (newParamId !== NEW ? <div style={linkContainerStyle}>
            <Button style={linkStyle} onClick={() => this.changeField(nextIndex)} type="submit">
              {nextIndexName}
              {/*intl.formatMessage({ id: 'NEXT_FIELD' })*/}
            </Button>
            <ArrowForwardIcon style={arrowStyle} />
          </div> : <div style={linkContainerStyle}>&nbsp;</div>) : 
          (newParamId !== NEW ? <div style={linkContainerStyle} sx={{disabled: true}}>
            <ArrowForwardIcon style={arrowStyleRightDisabled} />
            <span style={linkStyleDisabled}>{intl.formatMessage({ id: 'NEXT_FIELD' })}</span>
          </div> : <div style={linkContainerStyle}>&nbsp;</div>)}
        </div>}
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const { intl } = props;

  if (values.soilType) {
    const result = IntegerValidation(values.soilType);
    if (!result.valid || values.soilType < 1) {
      errors.soilType = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.moldClass) {
    const result = DecimalValidation(values.moldClass);
    if (!result.valid || values.moldClass <= 0) {
      errors.moldClass = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.phosphorus) {
    const result = DecimalValidation(values.phosphorus);
    if (!result.valid || values.phosphorus <= 0) {
      errors.phosphorus = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.potassium) {
    const result = DecimalValidation(values.potassium);
    if (!result.valid || values.potassium <= 0) {
      errors.potassium = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.acidSolublePotassium) {
    const result = DecimalValidation(values.acidSolublePotassium);
    if (!result.valid || values.acidSolublePotassium <= 0) {
      errors.acidSolublePotassium = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.ph) {
    const result = DecimalValidation(values.ph);
    if (values.ph > 14) {
      errors.ph = intl.formatMessage({ id: 'INVALID_PH_VALUE' });
    } else if (!result.valid) {
      errors.ph = intl.formatMessage({ id: 'INVALID_PH_VALUE' });
    }
  }
  return errors;
};

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  userDetails: state.userDetails.data,
  fieldSoilSamples: state.fieldSoilSamples,
  hasSupportAdminRole: state.SupportAdminRole,
  fieldDetails: state.fieldDetails.data1
});

const mapDispatchToProps = dispatch => ({
  fetchFieldList: farmId => dispatch(fieldActions.fetchFieldList1(farmId)),
  getSoilSamples: (farmId, fieldId) => dispatch(fieldSoilSampleActions.getFieldSoilSamples(farmId, fieldId)),
  addSoilSample: (farmId, fieldId, data) => dispatch(fieldSoilSampleActions.addFieldSoilSamples(farmId, fieldId, data)), 
  updateSoilSample: (farmId, fieldId, data, soilId) => dispatch(fieldSoilSampleActions.updateFieldSoilSamples(farmId, fieldId, data, soilId)),
  deleteSoilSample: (farmId, fieldId, soilId) => dispatch(fieldSoilSampleActions.deleteFieldSoilSamples(farmId, fieldId, soilId))
});

export default injectIntl(
  reduxForm({
    form: 'SoilSamplesList',
    validate
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(SoilSamplesList)))
);
