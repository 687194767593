import React from 'react';
import { injectIntl } from 'react-intl';

import Header from 'mui-components/header';
import Fields from 'mui-components/fields/fieldtab/Fields';
import { Box } from '@mui/material';

const ManageFields = ({ intl, location }) => (
  <Box className={`${'main-content'}`}>
    <Box >
      {(location.pathname).includes('managefields') &&
      <Header bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_2' })} />}
      {(location.pathname).includes('fertilization') &&
      <Header 
        bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_3' })}
        bcLink={{ to: '/managefields', text: intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_2' }) }}
        bcLinkArrow={true}
      />}
      {(location.pathname).includes('soilsamplesimport') &&
      <Header 
        bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_4' })}
        bcLink={{ to: '/managefields', text: intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_2' }) }}
        bcLinkArrow={true}
      />}
    </Box>
    <Box className="settings-tabs">
      <Fields />
    </Box>
  </Box>
);

export default injectIntl(ManageFields);
